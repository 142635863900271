import React, { useContext, useEffect, useRef, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuctionBYIdGet, AuctionGetTeam, BidReg, BidAuctionTeamWinner, UserGetId, getBIdCount, RefreshToken, BidStatus, AuctionAll } from '../../redux/auth-reducer/auth-service'
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs'
import Modal from 'react-bootstrap/Modal';
import soundFile from './sound.mp3';
import soundFileBid from './sound-bid.mp3';
import * as signalR from "@microsoft/signalr";
import { ImageUrl, SignalRUrl } from '../../utils/config/config'
import { Context } from '../../redux/Context'
import moment from 'moment'

export const PlaceBid = () => {

    const [data, setData] = useState(false);
    const [validated, setValidated] = useState(false);
    const [auctionList, setAuctionList] = useState([]);
    const [bidCountList, setBidCountList] = useState();
    const [userData, setUserData] = useState();
    const [oldBidAmount, setOldBidAmount] = useState();
    const [errorMessage, setErrorMessage] = useState(false)
    const [auctionIdData, setAuctionIdData] = useState();
    const [teamList, setTeamList] = useState();
    const [disabled, setDisabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { state } = useLocation()
    const [minutes, setMinutes] = useState(0);
    const [CurrentBidAmount, setCurrentBidAmount] = useState();
    const [seconds, setSeconds] = useState(1);
    const [show, setShow] = useState(false);
    const [bidWinner, setBidWinner] = useState([]);
    const intervalRef = useRef(null);
    const [signalRData, setSignalRData] = useState([]);
    // const [hubConnection, setHubConnection] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [bidStatusAuction, setBidStatusAuction] = useState();
    const [currentTotalPlaceBId, setCurrentTotalPlaceBId] = useState(0);

    const { hubConnection } = useContext(Context);


    // useEffect(() => {

    //     if (minutes === 0 && seconds == 0) {

    //         const audio = new Audio(soundFile);
    //         audio.play().catch(error => {
    //             console.error("Error playing sound:", error);
    //         });
    //     }

    // }, [minutes, seconds]);

console.log(state,"state????")
    useEffect(() => {
        let audio;
        if (minutes === 0 && seconds === 0) {
            audio = new Audio(soundFile);
            audio.play().catch(error => {
                console.error("Error playing sound:", error);
            });

            // Stop the sound after 3 seconds
            const timeoutId = setTimeout(() => {
                audio.pause();
                audio.currentTime = 0;
            }, 3000); // 3000 milliseconds = 3 seconds


            return () => {
                clearTimeout(timeoutId);
                if (audio) {
                    audio.pause();
                    audio.currentTime = 0;
                }
            };
        }
    }, [minutes, seconds]);



    useEffect(() => {
        let audioBId;
        if (minutes === 0 && seconds === 3) {
            audioBId = new Audio(soundFileBid);
            audioBId.play().catch(error => {
                console.error("Error playing sound:", error);
            });

            // Stop the sound after 3 seconds
            const timeoutId = setTimeout(() => {
                audioBId.pause();
                audioBId.currentTime = 0;
            }, 8000); // 3000 milliseconds = 3 seconds


            return () => {
                clearTimeout(timeoutId);
                if (audioBId) {
                    audioBId.pause();
                    audioBId.currentTime = 0;
                }
            };
        }
    }, [minutes, seconds]);




    useEffect(() => {
        const storedState = JSON.parse(localStorage.getItem('timerState'));
        if (storedState) {
            setMinutes(storedState.minutes);
            setSeconds(storedState.seconds);
        }
    }, []);


    // useEffect(() => {
    //     if (minutes === 0 && seconds === 0) {
    //         handleShow()
    //     }
    // }, [minutes, seconds])


    const handleClose = () => navigate("/player/AuctionPlayer");


    const [regForm, setRegForm] = useState({
        bidId: 0,
        auctionId: 0,
        teamId: Number,
        startingBid: 0,
        bidTime: 0,
        totalPlacedBids: 0,
        bidIncrement: 0,
        currentBidAmount: 0,
        bidAmount: 0,
        bidPlacedBy: 0
    });

    const navigate = useNavigate()

    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser.userId;

    // useEffect(() => {
    //     async function UserGet() {
    //         await UserGetId().then((res) => {
    //             setUserData(res?.id);

    //         })
    //     }
    //     UserGet();
    // }, []);

    const sidebarfun = () => {
        setData(!data)
    }

    // async function auctionTeamWinner(auctionId, teamId) {
    //     await BidAuctionTeamWinner(auctionId, teamId).then((res) => {
    //         if (res.data && res.data.length > 0) {
    //             console.log("first", res.data)
    //             setBidWinner(res.data)
    //         }

    //     })
    // }
    // console.log(bidWinner, "bidWinner")

    useEffect(()=>{
        if(currentTotalPlaceBId){
            console.log("commming")
            navigate(`/Player/PlaceBid?auctionId`, { state: {...state,...currentTotalPlaceBId} });
        }
    },[currentTotalPlaceBId])


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (currentTotalPlaceBId === undefined) {

            const registerData = [{
                // "crudOperationId": true,
                // "bidId": 0,
                "teamId": Number(state?.teamId),
                "auctionId": state?.auctionId,
                "startingBid": state?.startingBid,
                "bidTime": state?.bidTime,
                "totalPlacedBids": state?.totalPlacedBids,
                "bidIncrement": state?.bidIncrement,
                "currentBidAmount": Number(state?.startingBid),
                "bidAmount": state?.startingBid,
                // "bidPlacedBy": Number(userId),
            }]
            console.log(registerData, "if???????")
            await BidReg(registerData).then((res) => {

                if (res?.data?.status == true) {
                    setErrorMessage(false)

                    toast.success(res?.data?.message);
                    // setDisabled(true);
                    // navigate('/Player/Bid');
                    setCurrentTotalPlaceBId((prev) => {
                        return { ...prev, remainingBidTime: moment().add(state.bidTime, 'minutes').format() }
                    })

                }
                else {
                    toast.error(res?.data?.message);
                }
            })
        }
        // if (state?.bidIncrement + state?.startingBid > oldBidAmount) {
        //     setErrorMessage(true)
        // }
        else {
            const registerData = [{
                // "crudOperationId": true,
                // "bidId": 0,
                "teamId": Number(state?.teamId),
                "auctionId": currentTotalPlaceBId?.auctionId,
                "startingBid": currentTotalPlaceBId?.startingBid,
                "bidTime": currentTotalPlaceBId?.bidTime,
                "totalPlacedBids": currentTotalPlaceBId?.totalPlacedBids,
                "bidIncrement": currentTotalPlaceBId?.bidIncrement,
                // "currentBidAmount": Number(currentTotalPlaceBId?.bidIncrement + currentTotalPlaceBId?.startingBid),
                "currentBidAmount": Number(currentTotalPlaceBId?.currentBidAmount),
                "bidAmount": Number(oldBidAmount) || currentTotalPlaceBId?.currentBidAmount,
                "bidPlacedBy": Number(userId),
            }]
            console.log(registerData, "else???????")

            await BidReg(registerData).then((res) => {

                if (res?.data?.status == true) {

                    toast.success(res?.data?.message);
                    // setDisabled(true);
                    // navigate('/Player/Bid');
                    setCurrentTotalPlaceBId((prev) => {
                        return { ...prev, remainingBidTime: moment().add(state.bidTime, 'minutes').format() }
                    })

                }
                else {
                    toast.error(res?.data?.message);
                }
            })
        }

    };


    console.log(state, "state")
    useEffect(() => {

        const AuctionID = async () => {
            // await AuctionBYIdGet(state).then((response) => {
            setCurrentTotalPlaceBId({ ...state, remainingBidTime: state.recievedAt, totalPoolAmount: state?.totalPoolAmount, totalPlacedBids: state?.totalPlacedBids, currentBidAmount: state?.currentBidAmount, teamName: state?.teamName, startingBid: state?.startingBid })
            // setAuctionIdData(response?.auctionId)
            // setAuctionList(response);
            // setCurrentBidAmount(response?.bidIncrement + response?.minBid)
            // setOldBidAmount(response?.bidIncrement + response?.minBid);
            // })
        }
        AuctionID();

    }, [])

    // useEffect(() => {

    //     const GetTeamList = async () => {
    //         await AuctionGetTeam(auctionIdData).then((response) => {
    //             setTeamList(response?.data)

    //         })
    //     }
    //     GetTeamList();

    // }, [auctionIdData])

    // useEffect(() => {

    //     const BidCount = async () => {
    //         await getBIdCount().then((response) => {
    //             setBidCountList(response);

    //         })
    //     }
    //     BidCount();

    // }, [])

    var customId = [1, 2, 5, 6]

    const handelBid = (e) => {
        setRegForm({
            ...regForm,
            [e.target.name]: e.target.value
        }
        )
    }

    const handelBidAmount = (e) => {
        setErrorMessage(false);
        const value = e.target.value;
        if (value >= 0) {
            setInputValue(value);
        }
        // if (e.target.value.length > 6) {
        //     window.alert("Bid amount should not exceed 6 digits");
        // }

        // setDisabled(isNaN(value));
        setOldBidAmount(e.target.value);
    }


    function addZero(num) {

        if (num < 10) {
            return `0${num}`
        }
        return num

    }


    useEffect(() => {
        const updateTime = (time) => {
            console.log("time:::", time)
            // if(!time) return
            const [minutesStr, secondsStr] = time?.split(':') ?? [0, 0];
            const updatedMinutes = parseInt(minutesStr, 10);
            const updatedSeconds = parseInt(secondsStr, 10);

            // Only set state if values are valid
            if (!isNaN(updatedMinutes) && !isNaN(updatedSeconds)) {
                setMinutes(updatedMinutes);
                setSeconds(updatedSeconds);
                console.log("updatedMinutes === 0 && updatedSeconds", updatedMinutes, updatedSeconds)
                console.log(minutes, seconds, "dsffd")
                if (minutes === 0 && seconds === 0) {
                    console.log("zero value.............")
                    // handleShow(); // Trigger any action on zero
                }
                if (updatedMinutes === 0 && updatedSeconds === 0) {
                    // setDisabled(true)
                }
            }
        };

        function getLeaveTime(end) {
            var diffDays = (new Date(end)).getTime() - (new Date()).getTime()

            // // duration in minutes
            var minutes = parseInt(diffDays / (1000 * 60));

            var seconds = parseInt(diffDays / 1000);
            console.log("fff", diffDays, minutes, new Date(diffDays).getSeconds())
            if (minutes == 0 && new Date(diffDays).getSeconds() == 0) {
                setShow(true);
                // auctionTeamWinner(state.auctionId, state.teamId)
            }
            return diffDays > 0 ? `${diffDays > 0 ? addZero(minutes) : "00"}:${diffDays > 0 ? addZero(new Date(diffDays).getSeconds()) : "00"}` : ""
        }

        // Manage bid time initialization logic
        if (currentTotalPlaceBId === undefined) {
            if (state?.remainingBidTime === "00:01") {
                setMinutes(state.bidTime);
                console.log("time one")

            }
            else {
                console.log("time update")
                updateTime(getLeaveTime(state.recievedAt));
                // updateTime(currentTotalPlaceBId?.remainingBidTime);
            }
        } else {
            if (state?.remainingBidTime === "00:01") {
                // setMinutes(state.bidTime);
                console.log("time l state ", state)
                console.log("time currentTotalPlaceBId", currentTotalPlaceBId)
                console.log("time three")
            } else {
                updateTime(getLeaveTime(currentTotalPlaceBId?.remainingBidTime));
                // updateTime(getLeaveTime(state.recievedAt));
                console.log("time l four 55", currentTotalPlaceBId)

            }
        }


        if (currentTotalPlaceBId?.remainingBidTime === '00:00') {
            hubConnection.stop().then(() => {
                console.log("SignalR connection stopped");
            }).catch((error) => {
                console.error("Error stopping SignalR connection:", error);
            });
        }

    }, [state, currentTotalPlaceBId, hubConnection]);


    useEffect(() => {
        if (minutes === 0 && seconds === 0) {
            setShow(true);
            // auctionTeamWinner(state.auctionId, state.teamId)
        }
    }, [minutes, seconds])



    const handleShow = () => {
        if (!show) {
            setShow(true);
            // auctionTeamWinner(state.auctionId, state.teamId)
        }
    };



    useEffect(() => {
    if(currentTotalPlaceBId?.status=="Paused"){
    console.log("stopCountdown")
    }
    else{
        if (state.bidTime == 0.5) {
            if (minutes === 0 && seconds === 0) {
                clearInterval(intervalRef.current);
            } else {
                intervalRef.current = setInterval(() => {
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval(intervalRef.current);
                        } else {
                            setMinutes(0);
                            setSeconds(29);
                        }
                    } else {
                        setSeconds((prevSeconds) => prevSeconds - 1);
                    }
                }, 1000);
                return () => {
                    clearInterval(intervalRef.current);
                };
            }
        } else {
            if (minutes === 0 && seconds === 0) {
                clearInterval(intervalRef.current);
            } else {
                intervalRef.current = setInterval(() => {
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval(intervalRef.current);
                        } else {
                            setMinutes((prevMinutes) => prevMinutes - 1);
                            setSeconds(59);
                        }
                    } else {
                        setSeconds((prevSeconds) => prevSeconds - 1);
                    }
                }, 1000);
                return () => {
                    clearInterval(intervalRef.current);
                };
            }
        }
    }


       
    }, [minutes, seconds, state]);




    useEffect(() => {
        const storedState = JSON.parse(localStorage.getItem('timerState'));
        if (storedState) {
            setMinutes(storedState.minutes);
            setSeconds(storedState.seconds);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('timerState', JSON.stringify({ minutes, seconds }));
    }, [minutes, seconds]);

    useEffect(() => {
        return () => {
            localStorage.removeItem('timerState');
        };
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('timerState')) {
            setMinutes(5);
            setSeconds(0);
        }
    }, []);








    /***************** */

    // useEffect(() => {
    //     const BidStatusAll = async () => {
    //         await AuctionAll().then((res) => {
    //             setBidStatusAuction(res?.data);
    //             res?.data.map((item) => {
    //                 // if (state?.totalPlacedBids === 0) {
    //                 //     console.log("log 0 place bid")
    //                 // }
    //                 // else {
    //                 if (item.auctionId === state.auctionId && item.teamId === state.teamId) {
    //                   console.log(item,"item>>>>>>>>>>>>>>>>>>")
    //                     setCurrentTotalPlaceBId(item)
    //                 }
    //                 // }


    //             })
    //         });
    //     }
    //     BidStatusAll()
    // }, [])





    // useEffect(() => {
    //     const createHubConnection = async () => {
    //         let token = localStorage.getItem('isToken');
    //         const hubConnect = new signalR.HubConnectionBuilder()
    //             .withUrl(SignalRUrl, {
    //                 // https://gadienterprises-gamc-qa.chetu.com/hubs
    //                 // https://staging.xcutta.com/backend/hubs
    //                 skipNegotiation: true,
    //                 transport: signalR.HttpTransportType.WebSockets,
    //                 accessTokenFactory: () => token,
    //             })
    //             .withAutomaticReconnect()
    //             .build();

    //         const handleTokenExpiration = async () => {
    //             try {
    //                 const newToken = await RefreshToken(); // Assume this function returns a new token
    //                 token = newToken?.data?.token;
    //                 hubConnect.accessTokenFactory = () => token; // Update token factory
    //             } catch (error) {
    //                 console.error("Failed to refresh token:", error);
    //             }
    //         };

    //         try {
    //             await hubConnect.start(); // Try to start the connection
    //             console.log("SignalR Connection Established");
    //         } catch (err) {
    //             console.error("SignalR connection failed at first attempt:", err);
    //             // Attempt to refresh token and retry connection
    //             await handleTokenExpiration();
    //             try {
    //                 await hubConnect.start(); // Retry starting the connection
    //                 console.log("SignalR Connection Established after refreshing token");
    //             } catch (retryErr) {
    //                 console.error("SignalR connection failed after retry:", retryErr);
    //             }
    //         }

    //         hubConnect.onclose(async (error) => {
    //             console.error("SignalR connection closed. Error:", error);
    //             await new Promise(resolve => setTimeout(resolve, 5000));
    //             createHubConnection();
    //         });

    //         setHubConnection(hubConnect);
    //     };

    //     createHubConnection();

    //     return () => {
    //         if (hubConnection) {
    //             hubConnection.stop()
    //                 .then(() => console.log("SignalR Connection Stopped"));
    //         }
    //     };
    // }, []);

    useEffect(() => {
        if (hubConnection) {
            // Listen for messages from the SignalR hub
            hubConnection.on("ReceiveBidStatus", (data) => {
                console.log("Received Bid Statuses screen:", data);

                data.map((item) => {
                    console.log(data, "datatatta")
                    // if (state?.totalPlacedBids === 0) {
                    //     console.log("log 0 place bid")
                    // }
                    // else {
                    if (item.auctionId === state.auctionId && item.teamId === state.teamId) {
                        // let remainingtimer = moment().add(item?.remainingBidTime.split(":")[0], 'minutes').add(item?.remainingBidTime.split(":")[1], 'seconds').format()
                        // setCurrentTotalPlaceBId(item)
                        setCurrentTotalPlaceBId((e) => {
                            return { ...item, remainingBidTime: e.remainingBidTime }
                        })
                    }
                    // }


                })
                setSignalRData(data);
            });



            return () => {
                hubConnection.off("ReceiveBidStatus");
            };
        }
    }, [hubConnection]);


    useEffect(() => {
        if (hubConnection) {
            hubConnection.on("ReceiveCurrentBid", (data) => {
                console.log("ReceiveCurrentBid", data);
                // setCurrentTotalPlaceBId(data)
                data.map((item) => {
                    console.log(data, "datatatta")
                    // if (state?.totalPlacedBids === 0) {
                    //     console.log("log 0 place bid")
                    // }
                    // else {
                    if (item.auctionId === state.auctionId && item.teamId === state.teamId) {
                        let remainingtimer = moment().add(item?.remainingBidTime.split(":")[0], 'minutes').add(item?.remainingBidTime.split(":")[1], 'seconds').format()
                        setCurrentTotalPlaceBId({ ...item, remainingBidTime: remainingtimer })
                    }
                    // }


                })
            });
            return () => {
                hubConnection.off("ReceiveCurrentBid");
            };
        }
    }, [hubConnection]);

    // const [timeEnd, settimeEnd] = useState(4)

    // useEffect(() => {
    //     if (show) {
    //         var timerPointer
    //         timerPointer = setInterval(() => {
    //             settimeEnd(timeEnd - 1)
    //         }, 1000)
    //         setDisabled(true)
    //         setTimeout(() => {
    //             clearInterval(timerPointer)
    //             setDisabled(false)
    //         }, 1000 * 5);
    //     }
    // }, [show])


    useEffect(() => {
        // Check if the status is "Paused"
        if (currentTotalPlaceBId?.status === "Paused") {
            setTimeout(() => {
                navigate('/Player/AuctionPlayer');
              }, 5000);
            // Replace with your target route
        }
    }, [currentTotalPlaceBId?.status]);


    console.log(currentTotalPlaceBId, "currentTotalPlaceBId????????????????", minutes, seconds)




    return (
        <>


            <Modal show={show} 
            // onHide={handleClose}
             backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Bid Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    {/* {bidWinner?.map((item) =>
                        <>
                            {
                                item?.teamOwnerId ==userId?<h4><span className='text-success'>Congratulations,</span>You won the auction</h4>:<h4>Sorry, You lost the auction</h4>
                            }
                        </>
                    )} */}

                    {
                        currentTotalPlaceBId?.playerId == userId ? <h4><span className='text-success'>Congratulations,</span>You won the auction</h4> : <h4>Sorry, You lost the auction</h4>
                    }


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"  onClick={handleClose}>
                        OK 
                    </Button>

                </Modal.Footer>
            </Modal>




            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>

                    <Row>
                        <Col md={6}><h1 className="heading-dash">  Place Bid </h1></Col>
                    </Row>
                    <div className='group_data_table p-3'>
                        {/* <h2 className='currentBid text-center'>Highest Bid {state?.totalPlacedBids == 0 ? 0 : state?.startingBid}</h2> */}
                        <h2 className='currentBid text-center'>Total Pool : {currentTotalPlaceBId == undefined ? state?.totalPoolAmount : currentTotalPlaceBId?.totalPoolAmount}</h2>

                        <Row>
                            <Col md={6}>

                                <Form noValidate validated={validated} >
                                    <Form.Group as={Col} md="12 mt-3" className="text-left " controlId="validationCustomCountry">
                                        <Form.Label>Team</Form.Label>

                                        <InputGroup hasValidation>

                                            <Form.Control
                                                type="text"
                                                name="teamId"
                                                disabled
                                                value={currentTotalPlaceBId == undefined ? state?.teamName : currentTotalPlaceBId?.teamName}
                                                style={{ height: "46px" }}
                                                placeholder="Team Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please a select Team name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Row>

                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">  {state?.totalPlacedBids == 0 ? "Start Bid" : "Last Bid"}</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="minBid"
                                                    disabled
                                                    value={currentTotalPlaceBId == undefined ? state?.startingBid : currentTotalPlaceBId?.startingBid}
                                                    style={{ height: "46px" }}
                                                    placeholder="Enter your Minimum Bid"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Minimum Bid.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">Bid Time Limit</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="bidTimeLimit"
                                                    style={{ height: "46px" }}
                                                    disabled
                                                    value={currentTotalPlaceBId == undefined ? state?.bidTime : currentTotalPlaceBId?.bidTime}
                                                    placeholder="Enter your Bid Time Limit"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Bid Time Limit.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">Total Placed Bids</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="bidPlacedBy"
                                                    style={{ height: "46px" }}
                                                    disabled
                                                    // value={state?.totalPlacedBids}
                                                    value={currentTotalPlaceBId == undefined ? state?.totalPlacedBids : currentTotalPlaceBId?.totalPlacedBids}
                                                    placeholder="Enter your Placed Bids"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Placed Bids.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">Minimum Bid Increment</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="bidIncrement"
                                                    style={{ height: "46px" }}
                                                    disabled
                                                    value={state?.bidIncrement}

                                                    placeholder="Enter your Minimum Bid Increment"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Minimum Bid Increment.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>


                                        <Col md="6">
                                            <Form.Label className="mt-3">Minimum Next Bid </Form.Label>
                                            <h2 className='currentBid'>
                                                {/* {state?.totalPlacedBids === 0 ? state?.startingBid : currentTotalPlaceBId?.startingBid + state?.bidIncrement} */}
                                                {currentTotalPlaceBId?.currentBidAmount}
                                                {/* {currentTotalPlaceBId == undefined ? state?.startingBid : currentTotalPlaceBId.startingBid + state?.bidIncrement} */}
                                            </h2>
                                        </Col>
                                        <Col md="6" className='mt-3'>
                                            <Form.Group controlId="validationCustomFristname">
                                                <Form.Label >Place Custom Bidding Amount</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="number"
                                                        style={{ height: "46px" }}
                                                        name="bidAmount"
                                                        // minLength={6}
                                                        onChange={handelBidAmount}
                                                        placeholder="Enter Bid Amount"
                                                        aria-describedby="inputGroupPrepend"
                                                    // inputProps={{ maxLength: 6 }}
                                                    />

                                                </InputGroup>

                                            </Form.Group>

                                            {errorMessage == true ? <p className='err-message'>
                                                Bid Amount should be equal and greater than Minimum Next Bid.
                                            </p> : ""}
                                            <p className='mt-2'>    All Bidding Amount is in USD</p>



                                        </Col>

                                        <Form.Group as={Col} md="5">
                                            {/* {
                                                currentTotalPlaceBId?.startingBid===NaN ?"": <Button type='button' disabled={disabled} onClick={handleSubmit} className='update-btn-prof mt-3 ms-0'>Place My Bid</Button>
                                            } */}

                                        

                                            {
                                                isNaN(currentTotalPlaceBId?.startingBid)  ? "" :
                                                    minutes === 0 && seconds === 0 ?
                                                        <Button type='button' disabled onClick={handleSubmit} className='update-btn-prof  ms-0'>Place My Bid</Button> 
                                                        
                                                        
                                                        : <Button type='button' disabled={currentTotalPlaceBId?.playerId == userId || currentTotalPlaceBId?.status === "Paused"} onClick={handleSubmit} className='update-btn-prof ms-0'>Place My Bid</Button>
                                            }
                                            {currentTotalPlaceBId?.playerId == userId ? <>

                                                <p class="bidWinner mt-3">You are the highest bidder</p>

                                            </> : ""}

                                        </Form.Group>







                                    </Row>
                                </Form>

                                <div className='border-right'></div>
                            </Col>
                            <Col md={6}>
                                <div className='placeBid-sec'>
                                    <h2 className='text-white'>
                                        {state?.auctionName}
                                        - {state?.teamName}
                                      
                               

                                    </h2>
                                    {
                                            state?.teamImage==null?<img src='/image/team-icon.png' width={100}></img>: <img src={ImageUrl + state?.teamImage} width={100}></img>
                                        }

                                    <div className='group-auctionBid-date'>
                                        {show ? (
                                            <Col md={12}>
                                                <p>Bidding Ended</p>
                                            </Col>
                                        ) : (
                                            <Row>
                                                <Col md={12}>
                                                   {
                                                    currentTotalPlaceBId?.status==="Paused"? <p className='text-danger'>Paused</p>:
                                                    <span style={{ color: minutes === 0 && seconds <= 10 ? 'red' : 'white' }}>
                                                    {minutes?.toString().padStart(2, '0')}:{seconds?.toString().padStart(2, '0')}
                                                </span>
                                                   }

                                                  


                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    {
                                        state?.auctionImage ==null?<img src="../image/auctionImage.png" className='ImageAuction_bid' />:<img src={ImageUrl + state?.auctionImage} className='img-fluid' width={200}></img>
                                    }
                                    
                                </div>
                            </Col>
                        </Row>

                    </div>

                </div>
            </div>
        </>
    )
}


