
import React, { useContext, useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom'
import * as icons from 'react-bootstrap-icons';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { AuctionGetAll, AuctionDelete, AuctionUpdate, SportAllMap, TeamGetAll, TournamentAll, UploadImage, WinCount, AuctionPlayPause, SportGetById, RefreshToken } from '../../redux/auth-reducer/auth-service';
import { Footer } from '../../Footer';
import { ModalBox, ModalDelete } from '../Outer/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl, SignalRUrl } from '../../utils/config/config';
import { DatePicker, Loader } from 'rsuite';
import dayjs from 'dayjs';
import moment from 'moment';
import * as signalR from "@microsoft/signalr";
import { Context } from '../../redux/Context';

export const Auction = () => {
  const [data, setData] = useState();
  const [itemList, setItemList] = useState('');
  const [searchText, setSearchText] = useState('');
  const [itemSport, setItemSport] = useState([])
  const [count, setCount] = useState(0);
  const [deleteRow, setDeleteRow] = useState(0);
  const [showEdit, setShowEdit] = useState();
  const [validated, setValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageName, setImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [tounamentItem, setTounamentItem] = useState([]);
  const [pause, setPause] = useState(false);
  const [playStatus, setPlayStatus] = useState({});

  const [auctionImage, setAuctionImage] = useState();
  const [teamFkID, setTeamFkID] = useState([]);
  const [dataImage, setDataImage] = useState();
  const [itemTeam, setItemTeam] = useState();
  const [selectedTournament, setSelectedTournament] = useState();
  const [secondryMarketStatus, setSecondryMarketStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [number, setNumber] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [tournamentId, setTournamentId] = useState(null);
  const [auctionListWinner, setAuctionListWinner] = useState([])
  const [fnDisabled, setFnDisabled] = useState(true);
  const [fields, setFields] = useState([]);
  const [counter, setCounter] = useState(0);
  const [allXcuttaPercentage, setAllXcuttaPercentage] = useState();
  const [winningPlaceInptData, setWinningPlaceInptData] = useState([]);
  const [sportIdSelect, setSportIdSelect] = useState()
  const [errorMessageXcutta, setErrorMessageXcutta] = useState(false);
  const [winingPlace, setWiningPlace] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [filterUpcoming, setFilterUpcoming] = useState([]);
  const [filterLive, setFilterLive] = useState([]);
  const [filterClosed, setFilterClosed] = useState([]);
  const [filterPaused, setFilterPaused] = useState([]);
  const [loading, setLoading] = useState(false);
  const [winnerCount, setWinnerCount] = useState(0);
  const [winners, setWinners] = useState([]);
  const [bracketStatus, setBracketStatus] = useState([]);
  const [winnerTitleList, setWinnerTitleList] = useState("");
  const [winnerPercentageList, setWinnerPercentageList] = useState([]);
  const [editData, setEditData] = useState({
    auctionId: 0,
    auctionName: "",
    auctionImage: "",
    tournamentId: 0,
    sportId: 0,
    teamId: 0,
    startDate: "",
    bidTime: 0,
    winnerCount: 0,
    winnerTitle: [],
    winnerPercentage: [],
    xcuttaTake: 0,
    secondaryMarket: true
  });

  const { hubConnection } = useContext(Context);
  

  // const [hubConnection, setHubConnection] = useState(null);

  function sidebarfun() {
    setData(!data)
  }

  useEffect(() => {

    const SportAllFn = async () => {
      await SportAllMap().then((response) => {

        setItemSport(response?.data?.data?.dataList)
      })
    }
    SportAllFn();

  }, [])

  useEffect(() => {

    const SportByIDFn = async () => {
      await SportGetById(sportIdSelect).then((response) => {
        setBracketStatus(response?.data?.isBracket);

      })
    }
    SportByIDFn();

  }, [sportIdSelect]);

  const convertUTCToLocalTime = (utcDateString) => {
    const date = new Date(utcDateString);
    return date.toLocaleString();
  };

  const columns = [
    {
      name: <strong>Id</strong>,
      selector: (row, index) => index + 1,

    },

    {
      name: <strong>Auction Name</strong>,
      selector: (row) => row?.auctionName,
      sortable: true,
    },

    {
      name: <strong>Auction Image</strong>,
      selector: (row) =>

      // {
      //   row?.auctionImage == "" ? <img src="../image/auction.png" width={50} />:<img src={ImageUrl + row?.auctionImage} width={50} />
      // },
      {
        return !row?.auctionImage ?
          <img src="../image/auction.png" className="m-2" width={50} /> :
          <img src={`${ImageUrl}${row.auctionImage}`} className="m-2" width={50} />;
      },

      sortable: true,
    },

    {
      name: <strong>Contest Name</strong>,
      selector: (row) => row?.tournamentName,
      sortable: true,
    },
    {
      name: <strong>Sport Name</strong>,
      selector: (row) =>
        itemSport?.map((value) => {
          if (value.sportId == row?.sportId) {
            return value.sportName;
          }
        }),
      sortable: true,
    },

    {
      name: <strong>Start Date</strong>,
      selector: (row) => dayjs(row?.startDate).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: <strong>Start Time</strong>,

      selector: (row) => {
        const localTime = convertUTCToLocalTime(row?.startDate + "Z");
        const [datePart, timePart] = localTime.split(", ");
        return (
          <p>{timePart}</p>
        );
      },

    },

    {
      name: <strong>Bid Time Limit</strong>,
      selector: (row) => row?.bidTime,
      sortable: true,
    },
    {
      name: <strong>Secondary Market</strong>,
      width: "200px",
      selector: (row) =>
        row?.secondaryMarket == true ? (
          <div className="bg-succ">Available</div>
        ) : (
          <div className="bg-dang">Unavailable</div>
        ),
      sortable: true,
      sortFunction: (a, b) => {
        const statusA = a.secondaryMarket ? 'Available' : 'Unavailable';
        const statusB = b.secondaryMarket ? 'Available' : 'Unavailable';
        return statusA.localeCompare(statusB);
      },
    },

    {
      name: <strong>Bid Status</strong>,

      selector: (row) =>
        row?.status === "Upcoming" ? (
          <>
            <span style={{ color: "#f9c114" }}>Upcoming</span>
          </>
        ) : row.status === "Live" ? (
          <>
            <span style={{ color: "#043bc7" }}>Live</span>
          </>
        ) : row.status === "Paused" ? (
          <>
            <span style={{ color: "red" }}>Paused</span>
          </>
        ) : (
          <span style={{ color: "red" }}>Closed</span>
        ),
      sortable: true,
    },
    {
      name: <strong>Action</strong>,
      width: "200px",
      selector: (row) => (
        <>
          <button
            type="button"
            className="text-danger btn-aucion"
            onClick={() => deleteFn(row?.auctionId)}
          >
            <icons.Trash />
          </button>
          <button
            type="button"
            className="text-success btn-aucion"
            onClick={() => editFn(row)}
          >
            <icons.PencilSquare />
          </button>
          {row.status === "Paused" ? (
            <button
              type="button"
              className="text-success btn-aucion"
              onClick={() => playPausefN(row)}
            >
              <icons.Play style={{ border: "2px solid" }} />

            </button>
          ) : (
            <button
              type="button"
              className="text-success btn-aucion"
              onClick={() => playPausefN(row)}
            >
              <icons.Pause style={{ border: "2px solid" }} />
            </button>
          )}
        </>
      ),
      sortable: true,
    }

  ];

  const updatePlayStatus = (auctionId, status) => {
    setPlayStatus(prevStatus => ({
      ...prevStatus,
      [auctionId]: status,
    }));
    setPause(!pause)
  };

  const playPausefN = (row) => {
    const statusSend = row.status != "Paused" ? true : false;
    updatePlayStatus(row.auctionId, !playStatus[row.auctionId]);
    const AuctionPlayPauseStatus = async () => {
      await AuctionPlayPause(row.auctionId, statusSend).then((response) => {
        console.log("row", row, response)
        if (response?.data?.status === true) {
          setFilteredData((p) => p.map((e) => {
            if(e.auctionId == row.auctionId){
              return { ...e, status : row.status == "Paused" ? "Live" : "Paused"}
            }else{
              return e
            }
          }))
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
      });
    };
    AuctionPlayPauseStatus();
  };

  // let winnertitle = winners.map(winner => winner.name);
  // let winnerPercentage = winners.map(item => Number(item.winnerPercentage));
  // console.log(winnertitle,"winners item")
  // console.log(winnerPercentage,"winnerPercentage")

  const updatedWinnerTitles = winnerTitleList;
  const updatedWinnerPercentages = winnerPercentageList;
  console.log(updatedWinnerTitles, "updatedWinnerTitles")
  console.log(updatedWinnerPercentages, "updatedWinnerPercentages")



  const handleUpdate = async () => {

    setValidated(true);
    if (editData?.winnerCount) {

    }

    let totalPer = winningPlaceInptData.reduce((acc, item) => {
      item.input.map((elem) => {
        acc += Number(elem.winnerPercentage)
      })
      return acc
    }, 0)

    let winnertitle = winners.map(winner => winner.name);

    let winnerPercentage = winners.map(item => Number(item.winnerPercentage));

    totalPer += Number(editData?.xcuttaTake)

    const updateDate = auctionImage ? {
      "crudOperationId": false,
      // "teamId": editData?.teamId,
      "auctionId": editData?.auctionId,
      "auctionName": editData?.auctionName,
      "auctionImage": dataImage,
      "tournamentId": selectedTournament.trim(),
      "sportId": parseInt(sportIdSelect),
      "teamId": teamFkID,
      "startDate": editData?.startDate,
      "xcuttaTake": editData?.xcuttaTake,
      "bidTime": editData?.bidTime,
      "startingBid": editData?.startingBid,
      "bidIncrement": editData?.bidIncrement,
      "winnerCount": editData?.winnerCount,
      "winnerTitle": updatedWinnerTitles,
      "winnerPercentage": filteredWinnerPercentages,
      "secondaryMarket": secondryMarketStatus,
      "isPrivate":editData?.isPrivate,
    
    } : {
      "crudOperationId": false,
      // "teamId": editData?.teamId,
      "auctionId": editData?.auctionId,
      "auctionName": editData?.auctionName,
      "auctionImage": editData?.auctionImage,
      "tournamentId": selectedTournament.trim(),
      "sportId": parseInt(sportIdSelect),
      "teamId": teamFkID,
      "startingBid": editData?.startingBid,
      "bidIncrement": editData?.bidIncrement,
      "startDate": editData?.startDate,
      "xcuttaTake": editData?.xcuttaTake,
      "bidTime": editData?.bidTime,
      "winnerCount": editData?.winnerCount,
      "winnerTitle": updatedWinnerTitles,
      "winnerPercentage": filteredWinnerPercentages,
      "secondaryMarket": secondryMarketStatus,
      "isPrivate":editData?.isPrivate,
 
    }
    console.log(updateDate,"UPDATE????????????")

    await AuctionUpdate(updateDate).then((res) => {

      if (res?.status == true) {
        toast.success(res?.message);
        setShowEdit(false);
        setCount(count + 1);
      } else {
        toast.error(res?.message);
      }
    })

  }

  const editFn = (row, e) => {

    const event = (`${row?.sportId} - ${row.teamId} = ${row?.tournamentId}`)
    const [itemList, array, value] = event.split(/[-=]/)
    setTeamFkID(array.split(",").map((e) => parseInt(e)))

    setSelectedTournament(value)
    setSportIdSelect(itemList);
    setShowEdit(true);
    var valuesWinner = row?.winnerPercentage?.split(",")
    var finalData = { ...row, winnerPercentage: valuesWinner };

    setWinnerTitleList(row.winnerTitle.split(",").map(title => title.trim()));
    setWinnerPercentageList(row.winnerPercentage.split(",").map(Number));

    setEditData(finalData)

    setNumber(row?.winnerCount)

  }



  useEffect(() => {
    // Initialize winner titles and percentages based on editData
    if (editData.winnerCount) {
      const titles = Array.from({ length: winnerCount }, (_, index) => {
        return winnerTitleList[index] || `Place ${index + 1}`; // Default title if not set
      });
      const percentages = Array.isArray(editData.winnerPercentage)
        ? editData.winnerPercentage
        : editData.winnerPercentage.split(",").map(Number);

      setWinnerTitleList(titles);
      setWinnerPercentageList(percentages);
      setWinnerCount(editData.winnerCount); // Set initial winner count
    }
  }, [editData, winnerCount]);

  useEffect(() => {
    // Update winnerTitleList and winnerPercentageList based on winnerCount
    const updatedTitles = Array.from({ length: winnerCount }, (_, index) => {
      return winnerTitleList[index] || `Place ${index + 1}`; // Default title if not set
    });

    const updatedPercentages = Array.from({ length: winnerCount }, (_, index) => {
      return winnerPercentageList[index] || ''; // Default percentage if not set
    });

    setWinnerTitleList(updatedTitles);
    setWinnerPercentageList(updatedPercentages);
  }, [winnerCount]);

  useEffect(() => {
    if (editData?.startDate) {
      var localTime  = moment.utc(editData?.startDate).local().format();
      setStartDate(new Date(localTime));
    }
  }, [editData]);

  useEffect(() => {
    if (editData?.tournamentId) {
      setTournamentId(new Date(editData?.tournamentId));
    }
  }, [editData]);

  const handelEditSport = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value })
  }
  const deleteFn = (auctionId) => {
    setShowDeleteModal(true);
    setDeleteRow(auctionId)

  }
  const handleClosed = () => {
    setShowDeleteModal(false);
    setShowEdit(false);
  };

  const handelDelete = async () => {
    await AuctionDelete(deleteRow).then((res) => {
      if (res?.status == true) {
        setCount(count + 1);
        toast.success(res?.message);

        setShowDeleteModal(!showDeleteModal);
      } else {
        toast.error(res?.message)
      }
    })
  }

  useEffect(() => {

    const AuctionAllFn = async () => {
      setLoading(true);
      await AuctionGetAll().then((response) => {
        setLoading(false);
        setItemList(response?.data?.data?.dataList);

        setAuctionListWinner(response?.winnerPercentage?.split(",").map(Number));
      })
    }
    AuctionAllFn();

  }, [count]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handelFrom = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value
    }
    )
  }
  const handelAuctionTake = (e) => {
    setAllXcuttaPercentage(100 - e.target.value)

    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    },)
    setErrorMessage(false)
    setErrorMessageXcutta(false);
  }

  const handelDate = async (date) => {
    setEditData({
      ...editData,
      startDate: date,
    },)

  };

  const handelImage = (e) => {

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      setAuctionImage(e.target.files[0].name)
      reader.readAsDataURL(e.target.files[0]);
    }
    setSelectedFile(e.target.files[0].name)
    setImageName(e.target.files[0].name)
    const dataList = (e.target.files[0]);
    const imageAPI = async () => {
      const formDataImage = new FormData();
      formDataImage.append('file', dataList);
      await UploadImage(formDataImage).then((res) => {
        if (res?.data?.status == true) {
          setDataImage(res?.data?.data);

        } else {

        }
      })
    }

    imageAPI()
  }

  useEffect(() => {

    const TeamAllFn = async () => {
      await TeamGetAll().then((response) => {
        setItemTeam(response?.data?.data?.dataList);
      })
    }
    TeamAllFn();

  }, [])

  useEffect(() => {

    const TournamentAllFn = async () => {
      await TournamentAll().then((response) => {

        setTounamentItem(response?.data?.data?.dataList);

      })
    }
    TournamentAllFn();

  }, [])

  const handleSelectedTurnament = (event) => {

    const [itemList, array, value] = event.target.value.split(/[-=]/)
    setSportIdSelect(itemList);
    setTeamFkID(array.split(",").map((e) => parseInt(e)))
    setSelectedTournament(value)
    let teamsLength = array.split(",").map((e) => parseInt(e)).length
    if (teamsLength) {
      const AuctionWiningPlace = async () => {
        await WinCount(teamsLength).then((response) => {
          setWiningPlace(response?.data)
        })
      }



      AuctionWiningPlace();
    }

  }

  useEffect(() => {
    const teamFkIDString = teamFkID.join(', ');
    let teamsLength = teamFkIDString.split(",").map((e) => parseInt(e)).length
    if (teamsLength) {
      const AuctionWiningPlace = async () => {
        await WinCount(teamsLength).then((response) => {
          setWiningPlace(response?.data)
        })
      }
      AuctionWiningPlace();
    }
  }, [selectedTournament]);

  const handelCount = (e) => {

    const count = parseInt(e.target.value); // Get the selected value and convert it to an integer

    setWinnerCount(count); // Update the winner count state
    setEditData({
      ...editData,
      winnerCount: count // Update editData with the new winner count
    });

    //   const data = [];

    //   const newWinners = Array.from({ length: count }, (_, index) => ({
    //     name: `Place ${index + 1}`,
    //     winnerPercentage: editData
    // }));
    // setWinners(newWinners);
    //   setWinningPlaceInptData(data);
  }

  useEffect(() => {
    let data = []
    for (let i = 1; i <= number; i++) {
      let ordinal = i + getOrdinalSuffix(i) + " Place Winner (%)";
      data.push(ordinal);
    }
    setFields(data)

  }, [number])

  let dateString = editData?.dateOfBirth;
  let date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    let localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    var formattedDateOfBirth = localDate.toISOString().split('T')[0];
    let parts = formattedDateOfBirth.split('-');

  } else {
    console.error('Invalid date format');

  }

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);

  }

  useEffect(() => {
    if (searchText) {
      const filtered = itemList.filter((item) => {
        return (
          item?.auctionName?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.status?.toLowerCase().includes(searchText.toLowerCase()) ||
          item?.sportName?.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(itemList);
    }
  }, [searchText, itemList]);

  useEffect(() => {
    if (selectedOption === "live") {
      const filtered = filterLive.filter((item) =>
        item.status.toLowerCase().includes("live")
      );
      setFilteredData(filtered);
    } else if (selectedOption === "upcoming") {
      const filtered = filterUpcoming.filter((item) =>
        item.status.toLowerCase().includes("upcoming")
      );
      setFilteredData(filtered);
    } else if (selectedOption === "closed") {
      const filtered = filterClosed.filter((item) =>
        item.status.toLowerCase().includes("closed")
      );
      setFilteredData(filtered);
    } else if (selectedOption === "Paused") {
      const filtered = filterPaused.filter((item) =>
        item.status.toLowerCase().includes("paused")
      );
      setFilteredData(filtered);
    }
    else {
      setFilteredData(itemList);
    }
  }, [selectedOption, itemList]);

  useEffect(() => {
    if (itemList) {

      const filterUpcomings = (eventArray) => {
        return eventArray.filter((event) => event.status === "Upcoming");
      };
      setFilterUpcoming(filterUpcomings(itemList));

      const filterLives = (eventArray) => {
        return eventArray.filter((event) => event.status === "Live");
      };
      setFilterLive(filterLives(itemList));

      const filterCloses = (eventArray) => {
        return eventArray.filter((event) => event.status === "Closed");
      };
      setFilterClosed(filterCloses(itemList));

      const filterPauses = (eventArray) => {
        return eventArray.filter((event) => event.status === "Paused");
      };
      setFilterPaused(filterPauses(itemList));
    }
  }, [itemList]);

  const textEdit = (str, index) => {
    setFields((prev) => {
      return prev?.map((e, i) => {
        if (i == index) {
          return str
        } else {
          return e
        }
      })
    });

  }
  const handleClick = () => {
    setFnDisabled(false);
    setFnDisabled(!fnDisabled);
  }


 

  const handleWinningPlaceChange = (index) => (event) => {
    const newPercentage = event.target.value;
    const updatedList = [...winnerPercentageList];
    updatedList[index] = newPercentage; // Update the specific index
    setWinnerPercentageList(updatedList); // Update the state
};

  const handleTitleChange = (index) => (e) => {
    const newTitles = [...winnerTitleList];
    newTitles[index] = e.target.value;
    setWinnerTitleList(newTitles);
  };

  const getOrdinalSuffix = (number) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const value = number % 100;
    return number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
  };

  // useEffect(() => {

  //   editData.winnerCount = winningPlaceInptData.length

  // }, [winningPlaceInptData])

  const filteredTeams = itemTeam?.filter((listItem) => teamFkID?.includes(listItem?.teamId));
  const filteredWinnerPercentages = winnerPercentageList.slice(0, editData.winnerCount);
  useEffect(() => {
    setWinnerPercentageList(Array(editData.winnerCount).fill('')); // Reset to empty strings
  }, [editData.winnerCount]);

  useEffect(() => {
    // Initialize or update the winnerPercentageList based on editData.winnerCount
    const newList = Array.from({ length: editData.winnerCount }, (_, index) => {
      return winnerPercentageList[index] || ''; // Keep previous values if they exist
    });
    setWinnerPercentageList(newList);
  }, [editData.winnerCount]);

  // useEffect(() => {
  //   const createHubConnection = async () => {
  //     let token = localStorage.getItem('isToken');
  //     const hubConnect = new signalR.HubConnectionBuilder()
  //       .withUrl(SignalRUrl, {
  //           // https://gadienterprises-gamc-qa.chetu.com/hubs
  //               // https://staging.xcutta.com/backend/hubs
  //         skipNegotiation: true,
  //         transport: signalR.HttpTransportType.WebSockets,
  //         accessTokenFactory: () => token,
  //       })
  //       .withAutomaticReconnect()
  //       .build();

  //     const handleTokenExpiration = async () => {
  //       try {
  //         const newToken = await RefreshToken(); // Assume this function returns a new token
  //         token = newToken?.data?.token;
  //         hubConnect.accessTokenFactory = () => token; // Update token factory
  //       } catch (error) {
  //         console.error("Failed to refresh token:", error);
  //       }
  //     };

  //     try {
  //       await hubConnect.start(); // Try to start the connection
  //       console.log("SignalR Connection Established");
  //     } catch (err) {
  //       console.error("SignalR connection failed at first attempt:", err);
  //       // Attempt to refresh token and retry connection
  //       await handleTokenExpiration();
  //       try {
  //         await hubConnect.start(); // Retry starting the connection
  //         console.log("SignalR Connection Established after refreshing token");
  //       } catch (retryErr) {
  //         console.error("SignalR connection failed after retry:", retryErr);
  //       }
  //     }

  //     // Handle closed connections
  //     hubConnect.onclose(async (error) => {
  //       console.error("SignalR connection closed. Error:", error);
  //       // Optionally try to reconnect after a delay
  //       await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds before reconnection
  //       createHubConnection(); // Try to reconnect
  //     });

  //     setHubConnection(hubConnect);
  //   };

  //   createHubConnection();

  //   // Cleanup function to stop the connection when the component unmounts
  //   return () => {
  //     if (hubConnection) {
  //       hubConnection.stop()
  //         .then(() => console.log("SignalR Connection Stopped"));
  //     }
  //   };
  // }, []);



  // useEffect(() => {
  //   if (hubConnection) {
  //     // Listen for messages from the SignalR hub
  //     hubConnection.on("ReceiveBidStatus", (data) => {
  //       console.log(data,"ReceiveBidStatuses")
       
    
  
  //       setFilteredData((prev) => {
  //         return prev?.map((auction) => {
  //           let isLiveNow = data.filter((e) =>
  //             console.log( e.id ,"ffdssfd")
  //             // e.auctionId ==auction?.auctionId
  //           );
  //         // console.log(isLiveNow,"isLiveNow")
  //         //   if (isLiveNow) {    
  //         //     auction.status = isLiveNow?.status;
  //         //   }
  //         //   return auction;
  //         })
  //       });
  //     });

  //     // Cleanup the event listener on unmount
  //     return () => {
  //       hubConnection.off("ReceiveBidStatuses");
  //     };
  //   }
  // }, [hubConnection]);


  useEffect(() => {
    if (hubConnection) {
      // Listen for messages from the SignalR hub
      hubConnection.on("ReceiveBidStatus", (data) => {
        console.log(data, "ReceiveBidStatus");

        setFilteredData((prev) => {
          // Create a mapping of auctionId to status from the received data
          const statusMap = data.reduce((acc, e) => {
         
            acc[e.auctionId] = e.status; // Assuming 'status' is a property in the received data
            return acc;
          }, {});

          // Update the status of auctions based on the mapping
          return prev.map((auction) => {
            // If the auctionId exists in the statusMap, update the status
            if (statusMap[auction.auctionId]) {
            
              return {
                ...auction,
                status: statusMap[auction.auctionId], // Update status
              };
            }
            return auction; // Return the auction unchanged
          });
        });
      });

      // Cleanup the event listener on unmount
      return () => {
        hubConnection.off("ReceiveBidStatus");
      };
    }
  }, [hubConnection]);







  

  return (
    <>
      <ModalBox show={showEdit} onHide={handleClosed} title="Update Auction" body={
        <>
          <Row>
            <Form.Group as={Col} md={6} controlId="validationCustom02">
              <Form.Label>Auction Name</Form.Label>
              <Form.Control name="auctionName" value={editData?.auctionName} onChange={handelEditSport} className='select-option tournament' required type="text" />
              <Form.Control.Feedback type="invalid">
                Please enter Auction Name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6} className="text-left">
              <Form.Label >Select Contest  </Form.Label>
              <InputGroup hasValidation>
                <Form.Select name="tournamentId"

                  onChange={handleSelectedTurnament}
                  className='select-option tournament' required as="select">
                  <option>{editData?.tournamentName}</option>
                  {
                    tounamentItem?.map((item) => <>
                      <option key={item?.tournamentId}
                        value={`${item?.sportId} - ${item.teamId} = ${item?.tournamentId}`}
                      >{item?.tournamentName}</option>

                    </>
                    )
                  }
                </Form.Select >
                <Form.Control.Feedback type="invalid">
                  Please select a Contest.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {
              selectedTournament ? (
                <div>
                  <p>{bracketStatus ? <h5 className='text-success'>Bracket created</h5> : <h5 className='text-danger'>No Bracket</h5>}</p>

                </div>
              ) : (
                ""
              )
            }

            <p className={`${selectedTournament ? "mt-3 label" : ""}`}> {
              `${selectedTournament ? "Sport Name" : ""}`
            }  </p>
            <Form.Group as={Col} md={12}>
              <p className={`${selectedTournament ? "input-game" : ""}`}>

                {itemSport?.map((value) =>
                  value?.sportId == sportIdSelect && value?.sportName
                )}

              </p>

            </Form.Group>

            {selectedTournament ?
              <Form.Group as={Col} md={12} className='team-auction-update'>
                <Table className='table-team border'>
                  <thead>
                    <tr>
                      <th>Team</th>
                      <th>Name</th>

                    </tr>
                  </thead>
                  {

                    itemTeam?.map((listItem) => teamFkID?.map((item) =>
                      item == listItem?.teamId &&

                      <tbody>
                        <tr>
                          <td><img src="../image/image-team.png"></img></td>
                          <td >{listItem?.teamName}</td>
                        </tr>

                      </tbody>

                    ))

                  }
                </Table></Form.Group> : ""}

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Auction Start Date</Form.Label>
              <DatePicker

                format="MM/dd/yyyy HH:mm"
                value={startDate}
                name="startDate"
                onChange={handelDate}
                disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter Auction Start Date.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Bid Time Limit</Form.Label>
              <InputGroup hasValidation>
                <Form.Select name="bidTime" value={editData?.bidTime} onChange={handelFrom} className='select-option tournament' required as="select">
                  <option value={1} >1 minutues</option>
                  <option value={2} >2 minutues</option>
                  <option value={3}>3 minutues</option>
                  <option value={4} >4 minutues</option>
                  <option value={5} >5 minutues</option>
                  <option value={6} >6 minutues</option>
                  <option value={7} >7 minutues</option>
                  <option value={8} >8 minutues</option>
                  <option value={9} >9 minutues</option>
                  <option value={10} >10 minutues</option>
                </Form.Select >
                <Form.Control.Feedback type="invalid">
                  Please add Bid Time Limit.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Xcutta take % </Form.Label>
              <Form.Control name="xcuttaTake" value={editData?.xcuttaTake} onChange={handelAuctionTake} className='select-option tournament' required type="number" placeholder="Enter your cut" />
              <Form.Control.Feedback type="invalid">
                Please enter Xcutta take.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label className='mt-3'>Logo image </Form.Label>
              <input type='file' className='form-control' name="auctionImage" onChange={handelImage}  ></input><br />
            </Form.Group>
            <Form.Group as={Col} md={6}></Form.Group>
            {auctionImage ? <img src={selectedImage} className='imageAuction' alt="Selected" width={100} /> : <img src={ImageUrl + editData?.auctionImage} className='imageAuction' width={150}></img>}

            <p className='winner-distribution'>Winner Prize Distribution {100 - editData?.xcuttaTake} %</p>

            {
              allXcuttaPercentage === 0 || allXcuttaPercentage < 0
                ? ''
                : <>
                  <Form.Label>Total Winners </Form.Label>
                  <Form.Select
                    name="winnerCount"
                    onChange={handelCount}
                    className='select-option tournament'
                    required
                    as="select"
                    defaultValue={editData.winnerCount}
                  >
                    <option value="">Select Winner Count</option>
                    {filteredTeams?.length > 0 && (
                      [...Array(filteredTeams.length).keys()].map((num) => (
                        <option key={num + 1} value={num + 1}>
                          {num + 1}
                        </option>
                      ))
                    )}
                  </Form.Select>
                </>
            }

            {editData?.winnerCount > 0 && (
              <div className='winner-percentage'>
                <Row>
                  {Array.from({ length: editData.winnerCount }).map((_, levelIndex) => (
                    <Col md={12} key={levelIndex}>
                      <div>
                        <input
                          type='text'
                          className='input-editable'
                          name={`winnerName-${levelIndex}`}
                          placeholder={`Place ${levelIndex + 1}`}
                          value={winnerTitleList[levelIndex] || ''}
                          onChange={handleTitleChange(levelIndex)} // Update title on change
                          disabled={fnDisabled}
                        />

                        <input
                          name={`winnerPercentage-${levelIndex}`}
                          className='select-option tournament xcuttaTakeinput mb-2'
                          placeholder='Enter Percentage'
                          type="number"
                          value={winnerPercentageList[levelIndex] || ''}
                          onChange={handleWinningPlaceChange(levelIndex)} // Update percentage on change
                        />
                      </div>
                    </Col>
                  ))}
                </Row>

                {errorMessage && (
                  <p className='err-message'>
                    Please ensure that the total distribution among the winners should be equal to {allXcuttaPercentage}%
                  </p>
                )}
              </div>
            )}

          </Row>

        </>
      }

        footer={
          <>
            <Button variant="primary" className="btn-Save me-3" onClick={handleUpdate}>Update</Button>
            <Button type="button" className='btn-Cancel secondary' onClick={handleClosed} >Cancel</Button>
          </>
        } />

      <ModalDelete show={showDeleteModal} onHide={handleClosed} title="Delete Auction" body={
        <>
          Are you want to delete the auction?

        </>
      } footer={
        <>
          <Button variant="danger" onClick={handelDelete} >Delete</Button>
          <Button variant="secondary" onClick={handleClosed} >Close</Button>

        </>
      } />

      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <Row>
            <Col md={6}><h1 className="heading-dash">Auction</h1></Col>
            <Col md={6} className='text-right'>
              <Link to="/SuperAdmin/CreateAuction"> <Button className='primary button-create-auction'> <icons.Plus class="icon-plus" /> Create New Auction</Button></Link>
            </Col>

          </Row>

          <div className='group_data_table auction-list'>

            <div class="auction-manager search-input filter d-flex align-items-center">

              <label className='sort-status'>Sort By Status</label>

              <Form.Select
                name="select"
                value={selectedOption}
                onChange={handleChangeOption}
                className="me-4"
                required
                as="select"
              >
                <option value={""}>Select Status</option>
                <option value={"live"}>Live</option>
                <option value={"upcoming"}>Upcoming</option>
                <option value={"closed"}>Closed</option>
                <option value={"Paused"}>Paused</option>

              </Form.Select>

              <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />

            </div>
            {loading && <Loader />}
            <DataTable
              title="auction"
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30]}
              paginationTotalRows={filteredData?.length}
            />
          </div>

        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}


