import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/scss/main.css";
import "../src/assets/css/responsive.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import Organization from "./DashboardComponent/Outer/Organization.js";
import Login from "./DashboardComponent/Outer/Login.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,

} from "react-router-dom";
import ForgetEmail from "./DashboardComponent/Outer/ForgetEmail.js";
import UpdatePassword from "./DashboardComponent/Outer/UpdatePassword.js";
import Dashboard from "./DashboardComponent/Admin-dashboard/Dashboard.js";
import ChangePassword from "./CommonComponent/ChangePassword/ChangePassword.js";
import PrivateRoute from "./privateRoute/PrivateRoute.jsx";
import { OrganizationPayment } from "./DashboardComponent/Outer/OrganizationPayment.js";
import UserRegistration from "./DashboardComponent/Outer/UserRegistration.js";
import ForgotPassword from "./DashboardComponent/Outer/ForgotPassword.js";
import { ViewOrganization } from "./DashboardComponent/Outer/ViewOrganization.js";
import { SuperDashboard } from "./DashboardComponent/Super-admin/SuperDashboard.js";
import { Player_dashboard } from "./DashboardComponent/Player/Player_dashboard.js";
import { Profile } from "./CommonComponent/Profile/Profile.js";
import { PageNotFound } from "./DashboardComponent/Outer/PageNotFound.js";
import { User } from "./DashboardComponent/Admin-dashboard/User.js";
import { Sports } from "./DashboardComponent/Super-admin/Sports.js";
import { Team } from "./DashboardComponent/Super-admin/Team.js";
import { Tournament } from "./DashboardComponent/Super-admin/Tournament.js";
import { CreateTournament } from "./DashboardComponent/Super-admin/CreateTournament.js";
import WizardFormStepper from "./DashboardComponent/Outer/WizardFormStepper.js";
import { UserSuper } from "./DashboardComponent/Super-admin/UserSuper.js";
import { Auction } from "./DashboardComponent/Super-admin/Auction.js";
import { CreateAuction } from "./DashboardComponent/Super-admin/CreateAuction.js";
import { Announcement } from "./DashboardComponent/Super-admin/Announcement.js";
import { PlaceBid } from "./DashboardComponent/Player/PlaceBid.js";
import { SetupBracket } from "./DashboardComponent/Super-admin/SetupBracket.js";
import AuctionPlayer from "./DashboardComponent/Player/AuctionPlayer.js";
import { Bid } from "./DashboardComponent/Player/Bid.js";
import { Home } from "./DashboardComponent/Outer/Home.js";
import { StripePayement } from "./DashboardComponent/Outer/stripe/StripePayement.js";
import { Notification } from "./DashboardComponent/Player/Notification.js";

import { Account_settings } from "./DashboardComponent/Player/Account_settings.js";
import { Secondary_Market } from "./DashboardComponent/Player/Secondary_Market.js";
import { Admin } from "./DashboardComponent/Super-admin/Admin.js";
import { Admin_report } from "./DashboardComponent/Admin-dashboard/Admin_report.js";
import { Admin_notification } from "./DashboardComponent/Admin-dashboard/Admin_notification.js";
import { Admin_auction } from "./DashboardComponent/Admin-dashboard/Admin_auction.js";
import { PaymentCancel } from "./DashboardComponent/Outer/stripe/PaymentCancel.js";
import { PaymentSuccess } from "./DashboardComponent/Outer/stripe/PaymentSuccess.js";
import { Checkout } from "./DashboardComponent/Player/Checkout.js";
import { CreateCustomer } from "./DashboardComponent/Player/CreateCustomer.js";
import SuperNotification from "./DashboardComponent/Super-admin/SuperNotification.js";
import { AccountHistory } from "./DashboardComponent/Admin-dashboard/AccountHistory.js";
import { AboutUs } from "./DashboardComponent/Outer/AboutUs.js";
import { Features } from "./DashboardComponent/Outer/Features.js";
import { Works } from "./DashboardComponent/Outer/Works.js";
import { ContactUs } from "./DashboardComponent/Outer/ContactUs.js";
import { AccounSetting } from "./DashboardComponent/Super-admin/AccounSetting.js";
import "./utils/config/APIConfig.js"
// import "./utils/config/MyComponent.js"
import { PlayerAnnouncment } from "./DashboardComponent/Player/PlayerAnnouncement.js";
import { Query } from "./DashboardComponent/Admin-dashboard/Query.js";
import { SecodaryPlaceBid } from "./DashboardComponent/Player/SecodaryPlaceBid.js";
import { SecodaryBid } from "./DashboardComponent/Player/SecodaryBid.js";
import { Secondary_Market_Announcement } from "./DashboardComponent/Super-admin/Secondary_Market_Announcement .js";

import { Faq } from "./DashboardComponent/Outer/Faq.js";
import { Secondary_Market_AnnouncementP } from "./DashboardComponent/Player/Secondary_Market_Announcement.js";
import { Secondary_Market_AnnouncementA } from "./DashboardComponent/Admin-dashboard/Secondary_Market_Announcement.js";
import { ManagerTips } from "./DashboardComponent/Outer/ManagerTips.js";
import { Generate_PromoCode } from "./DashboardComponent/Admin-dashboard/Generate_PromoCode.js";
import { ToastContainer } from "react-toastify";
import { SignalRUrl } from "./utils/config/config.js";
import { RefreshToken } from "./redux/auth-reducer/auth-service.js";
import { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { setSignalRConnection } from "./redux/auth-reducer/auth-reducer.js";
import { AuctionResult } from "./DashboardComponent/Admin-dashboard/AuctionResult.js";



function App() {
   

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Organization" element={<Organization />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/Works" element={<Works />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/UserRegistration" element={<UserRegistration />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ForgetEmail" element={<ForgetEmail />} />
          <Route path="/UpdatePassword/:id" element={<UpdatePassword />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="WizardFormStepper" element={<WizardFormStepper />} />
          <Route path="StripePayement" element={<StripePayement />} />
          <Route path="PaymentCancel" element={<PaymentCancel />} />
          <Route path="PaymentSuccess" element={<PaymentSuccess />} />
          <Route path="Faq" element={<Faq />} />
          <Route path="ManagerTips" element={<ManagerTips />} />

          <Route path="WebAdmin">
            <Route element={<PrivateRoute role="Web Admin" />}>
              <Route path="" element={<Dashboard />} />
              <Route path="Dashboard" element={<Dashboard />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="ViewOrganization" element={<ViewOrganization />} />
              <Route path="Profile" element={<Profile />} />
              <Route path="User" element={<User />} />
              <Route path="Admin_report" element={<Admin_report />} />
              <Route path="Generate_PromoCode" element={<Generate_PromoCode />} />
              <Route path="AuctionResult" element={<AuctionResult />} />
              <Route
                path="Admin_notification"
                element={<Admin_notification />}
              />
              <Route
                path="Query"
                element={<Query />}
              />
              <Route path="Admin_auction" element={<Admin_auction />} />
              <Route path="AccountHistory" element={<AccountHistory />} />
              <Route path="SecondaryMarketResults" element={<Secondary_Market_AnnouncementA />} />
            </Route>
          </Route>

          <Route path="SuperAdmin">
            <Route element={<PrivateRoute role="Manager" />}>
              <Route path="" element={<SuperDashboard />} />
              <Route path="SuperDashboard" element={<SuperDashboard />} />
              <Route path="OrganizationPayment" element={<OrganizationPayment />} />
              <Route path="UserSuper" element={<UserSuper />} />
              <Route path="Profile" element={<Profile />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="Sports" element={<Sports />} />
              <Route path="Team" element={<Team />} />
              <Route path="Auction" element={<Auction />} />
              <Route path="Contest" element={<Tournament />} />
              <Route path="CreateContest" element={<CreateTournament />} />
              <Route path="CreateAuction" element={<CreateAuction />} />
              <Route path="AuctionResults" element={<Announcement />} />
              <Route path="SetupBracket/:id" element={<SetupBracket />} />
              <Route path="Admin" element={<Admin />} />
              <Route path="SuperNotification" element={<SuperNotification />} />
              <Route path="AccounSetting" element={<AccounSetting />} />
              <Route path="Secondary_Market_Results" element={<Secondary_Market_Announcement />} />
            </Route>
          </Route>

          <Route path="Player">
            <Route element={<PrivateRoute role="Player" />}>
              <Route path="" element={<Player_dashboard />} />
              <Route path="ChangePassword" element={<ChangePassword />} />
              <Route path="Player_dashboard" element={<Player_dashboard />} />
              <Route path="Profile" element={<Profile />} />
              <Route path="PlaceBid" element={<PlaceBid />} />
              <Route path="SecodaryPlaceBid" element={<SecodaryPlaceBid />} />
              <Route path="SecodaryBid" element={<SecodaryBid />} />
              <Route path="Bid" element={<Bid />} />
              <Route path="AuctionPlayer" element={<AuctionPlayer />} />
              <Route path="Notification" element={<Notification />} />
              <Route path="Account_settings" element={<Account_settings />} />
              <Route path="Secondary_Market" element={<Secondary_Market />} />
              <Route path="Checkout" element={<Checkout />} />
              <Route path="Checkout" element={<Checkout />} />
              <Route path="CreateCustomer" element={<CreateCustomer />} />
              <Route path="AuctionResults" element={<PlayerAnnouncment />} />
              <Route path="SecondaryMarketResults" element={<Secondary_Market_AnnouncementP />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
