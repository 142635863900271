import React, { useContext, useEffect, useMemo, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from '../../Footer';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import { ModalBox } from '../Outer/Modal';
import Table from 'react-bootstrap/Table';
import { CloseRounded } from '@mui/icons-material';
import { GetWinnerAuction, RefreshToken, SecondaryMarketAuction, SecondaryMarketBidSell, SecondaryMarketAnnouncement } from "../../redux/auth-reducer/auth-service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as icons from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { Card, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker } from 'rsuite';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import * as signalR from "@microsoft/signalr";
import { SignalRUrl } from "../../utils/config/config";
import { Context } from "../../redux/Context";
import moment from "moment";

export const Secondary_Market = () => {
    const [data, setData] = useState(false);
    const [auctionWinner, setAuctionWinner] = useState([])
    const [auctionToSellData, setAuctionToSellData] = useState("")
    const [startingBidToSell, setStartingBidToSell] = useState("")
    const [bidIncrementToSell, setBidIncrementToSell] = useState("")
    const [showView, setShowView] = useState(false);
    // const [hubConnection, setHubConnection] = useState(null);
    const [signalRData, setSignalRData] = useState([]);
    const [validated, setValidated] = useState(false);
    const [searchTextLive, setSearchTextLive] = useState();
    const [searchTextClose, setSearchTextClose] = useState("");
    const [searchTextUpcomingLive, setSearchTextUpcomingLive] = useState("");
    const [BidStatusAuction, setBidStatusAuction] = useState([]);
    const [filterClosedAuction, setFilterClosedAuction] = useState([]);
    const [filterUpcomingLiveAuction, setFilterUpcomingLiveAuction] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [dateValid, setDateValid] = useState(true);
    const [key, setKey] = useState('Sell Auctions');
    const [dataListRecent,setDataListRecent] =useState([])
    const { hubConnection } = useContext(Context);


    const navigate = useNavigate();
    const [regForm, setRegForm] = useState({
        startingBid: '',
        ownerShipSold: '',
        startDate: ""
    })
    const handleClose = () => {
        setShowView(false)
        setDateValid(true)
    };

    const handleShowView = (row) => {

        setShowView(true);
        console.log("row", row)
        setAuctionToSellData(row)
    }

    const handlebidIncrementToSell = async (e) => {
        e.preventDefault();
        setBidIncrementToSell(e.target.value)
    };

    const sidebarfun = () => {
        setData(!data)
    }
    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser.userId;
    console.log("sss", auctionWinner)
    const handleSell = async (event) => {
        if (!regForm?.startDate) {
            setDateValid(false);
            setValidated(true);
            return;
        } else {
            setDateValid(true);

        }

        // const BidStatusAll = async () => {
        //     await GetWinnerAuction().then((res) => {
        //         const sortedData = res?.data?.data?.dataList.sort((a, b) => new Date(b.date) - new Date(a.date));
        //         sortedData.reverse();
        //         setBidStatusAuction([...sortedData]);
        //     });
        // }
        // BidStatusAll()

        event.preventDefault();


        console.log("auctionToSellData 77777777777777", auctionToSellData)
        console.log("regForm?.startingBid", Number(regForm?.startingBid))
        if (Number(regForm?.startingBid) > 0) {
            const dataList = [{
                "crudOperationId": true,
                "id": 0,
                "auctionId": auctionToSellData?.auctionId,
                "teamId": auctionToSellData?.teamId,
                "teamOwnerId": auctionToSellData?.teamOwnerId,
                "bidAmount": auctionToSellData?.bidAmount,
                "startingBid": Number(regForm?.startingBid),
                "bidIncrement": auctionToSellData?.bidIncrement,
                "startDate": regForm?.startDate,
                "maxOwnerShip": auctionToSellData?.maxOwnerShip,
                "ownerShipSold": Number(regForm?.ownerShipSold),
            }]

            console.log("ttttttttttttt", dataList)
            await SecondaryMarketBidSell(dataList).then((res) => {
                console.log("res::::", res)
                if (res?.data?.status == true) {
              
                    setShowView(false);
                      toast.success(res?.data?.message)
                    setAuctionToSellData('');
                    setStartingBidToSell('');
                    setBidIncrementToSell('')
                }
                else {
                    toast.error(res?.data?.message)
                }
            })


        }

    }

    useEffect(() => {

        const GetSecoderyAuction = async () => {
            await SecondaryMarketAuction().then((response) => {
                setAuctionWinner(response?.data?.data?.dataList.filter((event) => event.status === "Live"))

            })
        }
        GetSecoderyAuction();

    }, [])

    const handelForm = (e) => {
        setRegForm({
            ...regForm,
            [e.target.name]: e.target.value
        }
        )
    }

    const handelDate = (e) => {
        setDateValid(true);
        setRegForm({
            ...regForm,
            startDate: e,
        },)
    }

    const btnplacebid = (item) => {
        navigate(`/Player/SecodaryPlaceBid?auctionId`, { state: item });
    }
    const convertUTCToLocalTime = (utcDateString) => {
        const date = new Date(utcDateString);
        return date.toLocaleString();
    };

    const column = [
        {
            name: 'ID',
            selector: (row, index) => index + 1,

        },
        {
            name: 'Team Name',
            selector: (row) => row.teamName,
            sortable: true,
        },
        {
            name: 'Auction Name',
            selector: (row) => row.auctionName,
            sortable: true,
        },
      

        {
            name: 'Bid Amount',
            selector: (row) => row.bidAmount,
            sortable: true,
        },
        {
            name: 'Max Owner Ship',
            selector: (row) => row.maxOwnerShip,
            sortable: true,
        },
        {
            name: 'Bid Increment',
            selector: (row) => row.bidIncrement,
            sortable: true,
        },

        {
            name: 'Action',
            selector: (row) =>


                <Button 
                // disabled={row.status != "Closed" ? true : false} 
                onClick={() => handleShowView(row)} >Sell</Button>,
            sortable: true,
        },
    ];

    const handleSearchLive = (e) => {
        setSearchTextLive(e.target.value);
    };

    const filteredDataAuctionWinner = searchTextLive ? auctionWinner.filter((item) => {

        return item.status.toLowerCase() === 'live' && item?.auctionName?.toLowerCase().includes(searchTextLive.toLowerCase());
    }) : auctionWinner;


    
      var tempTimeOut = false
    
      useEffect(() => {
        let isListLive = [...BidStatusAuction];
    
        clearInterval(tempTimeOut)
        if (isListLive?.length > 0) {
          var num = (new Date()).getTime();
          let now = moment().utc();
          tempTimeOut = setInterval(() => {
            console.log("Interval running")
            let remaingData = isListLive.sort(function (a, b) { return a.teamId - b.teamId })
              .sort(function (a, b) { return new Date(a.startDate) - new Date(b.startDate) })
              .filter(function (a) {
                // now.isAfter(a.startDate) && 
                if (a.status == "Upcoming") {
                  return { ...a, recievedAt: new Date() }
                } else if (now.isAfter(a.startDate) && a.status == "Live") {
                  let remainingtimer = moment().add(a?.remainingBidTime.split(":")[0], 'minutes').add(a?.remainingBidTime.split(":")[1], 'seconds').format()
                  return { ...a, recievedAt: remainingtimer }
                }
                return a
                // return (num - new Date(a.startDate + 'Z').getTime()) < 0; })
              })
            console.log("remaingData", remaingData.filter((e) => e.status == "Upcoming"), BidStatusAuction.filter((e) => e.status == "Upcoming"))
            setBidStatusAuction(remaingData)
    
            // let liveFound = remaingData?.filter((event) => event.status === "Live");
    
            // liveFound
    
          }, 1000)
        }
    
        return () => {
          clearInterval(tempTimeOut)
        }
      }, [BidStatusAuction])
    

    useEffect(() => {
        if (hubConnection) {
            // Listen for messages from the SignalR hub
            hubConnection.on("ReceiveSMBidStatus", (data) => {
                console.log("Received SM Bid Statuses: signal", data);
                // setSignalRData(data);

                if (data?.length > 0) {

                    setBidStatusAuction((prev) => {
                        let newData = prev.map((item) => {

                            let isLiveNow = data.filter((e) => e.auctionId == item.auctionId && e.teamId == item.teamId && e.auctionLevel == item.auctionLevel && e.status == 'Live');

                            let isPresentList = data.filter((e) => e.auctionId == item.auctionId && e.teamId == item.teamId && e.auctionLevel == item.auctionLevel)
                            // console.log(item, "isLiveNow")

                            if (isLiveNow?.length > 0) {
                                let remainingtimer = moment().add(isLiveNow[0]?.remainingBidTime.split(":")[0], 'minutes').add(isLiveNow[0]?.remainingBidTime.split(":")[1], 'seconds').format()
                                return { ...item, status: (isLiveNow[0].status == "Live" && isLiveNow[0].remainingBidTime == "00:00") ? "Closed" : "Live", totalPoolAmount: isLiveNow[0]?.totalPoolAmount, recievedAt: remainingtimer }
                            } else if (isPresentList[0]?.status == "Paused") {
                                return { ...item, status: "Paused" }
                            } else if (item.status == "Live") {
                                return { ...item, status: "Closed", recievedAt: new Date() }
                                
                            } 
                            
                            else if (isPresentList?.length > 0) {
                                return { ...item, status: isPresentList[0].status, totalPoolAmount: isLiveNow[0]?.totalPoolAmount }
                            }
                            return { ...item, recievedAt: new Date() }
                        })
                        // setTimer(newData.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused"))
                        return newData
                    })
                }

            });

            hubConnection.on("ReceiveCurrentSMBid", (data) => {
                console.log("Received SM current Bid Statuses: signal", data);
                data.map((oldRest) => {
                    console.log(data, "datatatta")
                    setBidStatusAuction((prev) => {
                        let newData = prev.map((item) => {
                            if (oldRest.auctionId == item.auctionId && oldRest.teamId == item.teamId && oldRest.auctionLevel == item.auctionLevel && oldRest.status == 'Live') {
                                let remainingtimer = moment().add(oldRest?.remainingBidTime.split(":")[0], 'minutes').add(oldRest?.remainingBidTime.split(":")[1], 'seconds').format()
                                return { ...item, recievedAt: remainingtimer }
                            }
                            return item
                        })
                        return newData
                      
                    })
                    // setDataListRecent(newData)
                })


            });

            
        


            hubConnection.on("GetRecentSMAuction", (data) => {
                console.log("GetRecentSMAuction:::", data);
              
            //     setBidStatusAuction((prev) => {
            //         let newData = prev.map((item) => {
            //             let filterData = data.filter((e) => e?.auctionId == item?.auctionId && e?.teamId == item?.teamId && e.auctionLevel == item.auctionLevel)
            //             if (filterData?.length > 0) {
            //                 return { ...filterData[0], status : "Upcoming", recievedAt: new Date() }
            //             }
            //             return item
            //         })
            //         return newData
            //     })

            // });
            let newData = data.map((e) => {
                return {...e , recievedAt: new Date()}
              })
      
              setBidStatusAuction((prev) => [ ...prev, ...newData])
      
            });

            // Cleanup the event listener on unmount
            return () => {
                hubConnection.off("ReceiveSMBidStatus");
                hubConnection.off("ReceiveCurrentSMBid");
                hubConnection.off("GetRecentSMAuction");
            };
        }
    }, [hubConnection]);

    console.log(dataListRecent,"bidstatusdat??????")
    
      function addLiveTeam(data) {
        if (!data) return
        if (data?.status != "Upcoming") return
        console.log("mmm Upcoming::")
        setBidStatusAuction((prev) => {
          let newData = prev.map((item) => {
            if (data.auctionId == item.auctionId && data.teamId == item.teamId && item.status != 'Live' && data.auctionLevel == item.auctionLevel) {
              let remainingtimer = moment().add(item.bidTime, 'minutes').format()
              return { ...item, status: "Live", recievedAt: remainingtimer }
            }
            //  else if(data.auctionId == item.auctionId && data.teamId == item.teamId){
            //   return { ...item, status: "Closed" }
            //   // return { ...item }
            //  }
            return item
          })
    
          // setTimer(newData.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused"))
          return newData
        })
      }
    
      function addLiveToNextTeam(data) {
        if (data.status != "Live") return
        console.log("mmm Live::")
        setBidStatusAuction((prev) => {
          let newData = prev.map((item) => {
            if (data.auctionId == item.auctionId && data.teamId == item.teamId && data.auctionLevel == item.auctionLevel) {
              return { ...item, status: "Closed" }
              // return { ...item }
            }
            return item
          })
    
          // setTimer(newData.filter((event) => event.status === "Upcoming" || event.status === "Live" || event.status === "Paused"))
          return newData
        })
      }
    
    
      function addZero(num) {
    
        if (num < 10) {
          return `0${num}`
        }
        return num
    
      }
    
      function getExectTime(end, nextItemStart) {
        let now = moment();
        var diffDays = nextItemStart.status == "Upcoming" ? ((new Date(nextItemStart.startDate + 'Z')).getTime() - (new Date()).getTime()) : ((new Date(end)).getTime() - (new Date()).getTime());
        // console.log("fff:::::", end, moment(end).format("ddd/mm/yyyy hh:mm:ss"))
        // var startTime = moment().format('HH:mm:ss a');
        // var endTime = moment(end, 'HH:mm:ss a');
        // let fff = moment(moment().diff(end)).format('h m s');
        // console.log("fff", fff)
        // // calculate total duration
        // var duration = moment.duration(endTime.diff(startTime));
    
        // // duration in hours
        // var hours = parseInt(diffDays.asHours());
    
        // // duration in minutes
        var minutes = Math.abs(parseInt(diffDays / (1000 * 60)));
        let allAuctionBid = BidStatusAuction.filter((e) => e.auctionId == nextItemStart.auctionId).sort(function (a, b) { return a.teamId - b.teamId });
        let isAnyLive = allAuctionBid.some((e) => e.status == "Live")
    
        var seconds = parseInt(diffDays / 1000);
        console.log("fff 1", nextItemStart.status, "<==", isAnyLive, diffDays, allAuctionBid,nextItemStart, minutes, new Date(diffDays).getSeconds())
    
        if (!isAnyLive && nextItemStart.status == "Upcoming" && minutes == 0 && new Date(diffDays).getSeconds() == 0) {
          addLiveTeam(nextItemStart)
        } 
        else if (diffDays && minutes == 0 && new Date(diffDays).getSeconds() == 0 && nextItemStart.status != "Upcoming") {
          addLiveToNextTeam(nextItemStart)
        }
    
        // if(diffDays && nextItemStart.status == "Upcoming"){
        //   addLiveTeam(nextItemStart)
        // }else if(diffDays && minutes == 0 && new Date(diffDays).getSeconds() == 0 && nextItemStart.status != "Upcoming"){
        //   // if(nextItemStart.status == "Upcoming"){
        //   //   console.log("left upcoming", ((new Date()).getTime() - (new Date(end)).getTime()))
        //   //   addLiveTeam(nextItemStart)
        //   // }else{
        //     addLiveToNextTeam(nextItemStart)
        //   // }
        // }
        return nextItemStart.status == "Live" ? `${minutes > 0 ? addZero(minutes) : "00"}:${new Date(diffDays).getSeconds() > 0 ? addZero(new Date(diffDays).getSeconds()) : "00"}` : ""
      }



    const columns = [

        {
            name: "Team Name",
            selector: (row) => row.teamName,
            sortable: true,
        },
        {
            name: "Auction Name",
            selector: (row) => row.auctionName,
            sortable: true,
        },
        {
            name: "Contest Name",
            selector: (row) => row.tournamentName,
            sortable: true,
        },
        {
            name: "Sport Name",
            selector: (row) => row.sportName,
            sortable: true,
        },

        {
            name: "Start Date",
            selector: (row) => dayjs(row.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>Start Time</strong>,

            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: "Bid Time Limit",
            selector: (row) => row.bidTime,
            sortable: true,
        },

        {
            name: "Starting Bid",
            selector: (row) => row.startingBid,
            sortable: true,
        },
        {
            name: "Bid Increment",
            selector: (row) => row.bidIncrement,
            sortable: true,
        },

        {
            name: "Bid Status",
            selector: (row) =>
                row.status === "Upcoming" ? (
                    <>
                        <span style={{ color: "#f9c114" }}>Upcoming {getExectTime(row.recievedAt, row)}</span>
                    </>
                ) : row.status === "Live" ? (
                    <>
                        <span style={{ color: "#043bc7" }}>Live {getExectTime(row.recievedAt, row)}</span>
                    </>
                ) : row.status === "Closed" ? (
                    <>
                        <span style={{ color: "red" }}>Closed</span>
                    </>
                ) : row.status === "Paused" ? (
                    <>
                        <span style={{ color: "red" }}>Paused</span>
                    </>
                ) : row.status === "Archive" ? (
                    <>
                        <span style={{ color: "red" }}>Closed</span>
                    </>
                ) : (
                    <>
                        <span style={{ color: "green" }}>Play</span>
                    </>
                ),

            sortable: true,
        },
        {
            name: "Created By",
            selector: (row) => row.createdByName,
            sortable: true,
        },
        {
            name: "",
            selector: (row) => (
                <>
                    {row.status === "Live" ? <button
                        // className="tablebtn placebid disable"
                       className={row.createdById === userId?"tablebtn placebid disable":"tablebtn placebid"}
                        disabled={row.createdById === userId}
                        onClick={() => btnplacebid(row)}
                    >
                        Place Bid
                    </button> :
                        <button
                            className="tablebtn placebid disable"
                            disabled

                            onClick={() => btnplacebid(row)}
                        >
                            Place Bid
                        </button>
                    }

                </>
            ),
            sortable: true,
        },

    ];

    const Columns_SM_Winners = [

        {
            name: "Team Name",
            selector: (row) => row.teamName,
            sortable: true,
        },
        {
            name: "Auction Name",
            selector: (row) => row.auctionName,
            sortable: true,
        },

        {
            name: "Start Date",
            selector: (row) => dayjs(row.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: "Start Time",

            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: "End Date",
            selector: (row) => dayjs(row.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: "End Time",

            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: "Bid Amount",
            selector: (row) => row.bidTime,
            sortable: true,
        },

        {
            name: "Bid Status",
            selector: (row) =>
                row.status === "Upcoming" ? (
                    <>
                        <span style={{ color: "#f9c114" }}>Upcoming</span>
                    </>
                ) : row.status === "Live" ? (
                    <>
                        <span style={{ color: "#043bc7" }}>Live</span>
                    </>
                ) : row.status === "Closed" ? (
                    <>
                        <span style={{ color: "red" }}>Closed</span>
                    </>
                ) : row.status === "Paused" ? (
                    <>
                        <span style={{ color: "red" }}>Paused</span>
                    </>
                ) : (
                    <>
                        <span style={{ color: "green" }}>Play</span>
                    </>
                ),

            sortable: true,
        },

    ];

    const handleSearchClose = (e) => {
        setSearchTextClose(e.target.value);
    };
    const handleSearchUpcomingLive = (e) => {
        setSearchTextUpcomingLive(e.target.value);
    };

    const filteredDataUpcomingLiveAuction = searchTextUpcomingLive ? filterUpcomingLiveAuction.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.sportsName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.status.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase())

    }) : filterUpcomingLiveAuction;

    const filteredDataCloseAuction = searchTextClose ? filterClosedAuction.filter((item) => {
        return item?.auctionName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item.sportsName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextClose.toLowerCase()) || item?.status?.toLowerCase().includes(searchTextClose?.toLowerCase())
    }) : filterClosedAuction;


    const filterListData = useMemo(() => {
        console.log({ BidStatusAuction })
        let allList = BidStatusAuction.filter((item) => {
            return searchTextUpcomingLive?.length > 0 ? item?.auctionName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.sportsName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) || item?.status.toLowerCase().includes(searchTextUpcomingLive?.toLowerCase()) : item

        })

        let liveOnTop = allList.filter((event) => event.status === "Live")
        let otherLeft = allList.filter((event) => event.status !== "Live")
        console.log("liveOnTop", liveOnTop)
        return [...liveOnTop, ...otherLeft]

    }, [BidStatusAuction, searchTextUpcomingLive])

    // const filteredDataSMWinners = searchTextClose ? filterClosedAuction.filter((item) => {
    //     return item?.auctionName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.teamName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item.sportsName?.toLowerCase().includes(searchTextClose?.toLowerCase()) || item?.createdByName?.toLowerCase().includes(searchTextClose.toLowerCase()) || item?.status?.toLowerCase().includes(searchTextClose?.toLowerCase())
    // }) : filterClosedAuction;

    useEffect(() => {
        const filterUpcomingsLivesAuction = (eventArray) => {
            return eventArray?.filter((event) => event.status === "Upcoming" || event.status === "Live");
        };

        setFilterUpcomingLiveAuction(filterUpcomingsLivesAuction(BidStatusAuction));
        // console.log(filterUpcomingsLivesAuction(BidStatusAuction), "filterUpcomingsLivesAuction(BidStatusAuction)");

        const filterClosesAuction = (eventArray) => {
            return eventArray?.filter((event) => event.status === "Closed" || event.status === "Archive");
        };
        setFilterClosedAuction(filterClosesAuction(BidStatusAuction));
    }, [signalRData, BidStatusAuction]);

    // console.log(filterUpcomingLiveAuction, "filterUpcomingLiveAuction");

    // useEffect(() => {
    //     if (signalRData && BidStatusAuction) {
    //         const updatedAuctionData = BidStatusAuction.map((auction) => {
    //             const matchingSignalRAuction = signalRData.find((signalRAuction) => signalRAuction.auctionId === auction.auctionId && signalRAuction.teamId === auction.teamId);
    //             // console.log(matchingSignalRAuction, "matchingSignalRAuction");
    //             if (matchingSignalRAuction) {
    //                 auction.currentBidAmount = matchingSignalRAuction.currentBidAmount;
    //                 auction.startingBid = matchingSignalRAuction.startingBid;
    //                 auction.status = matchingSignalRAuction.status;
    //                 auction.remainingBidTime = matchingSignalRAuction.remainingBidTime;
    //                 auction.totalPlacedBids = matchingSignalRAuction.totalPlacedBids;

    //             }
    //             return auction;
    //         });
    //         // console.log(updatedAuctionData, "updatedAuctionData");
    //         setBidStatusAuction(updatedAuctionData);
    //     }
    // }, [signalRData]);

    console.log(filterListData, "signalRData");
    // console.log(BidStatusAuction, "BidStatusAuction");

    useEffect(() => {
        const BidStatusAll = async () => {
            await GetWinnerAuction().then((res) => {
                const sortedData = res?.data?.data?.dataList.sort((a, b) => new Date(b.date) - new Date(a.date)).map((e) => {
                        let remainingtimer = e.status == "Upcoming" ? new Date() : moment().add(e?.remainingBidTime.split(":")[0], 'minutes').add(e?.remainingBidTime.split(":")[1], 'seconds').format()
                        return { ...e, recievedAt: remainingtimer, status: (e.status == "Live" && e.remainingBidTime == "00:00") ? "Closed" : e.status }
                      });
                sortedData.reverse();
                setBidStatusAuction([...sortedData]);
            });
        }
        BidStatusAll()
    }, [])

    const handleSelect = (k) => {
        setKey(k);
        // if (k === 'profile') {
        //     const BidStatusAll = async () => {
        //         await GetWinnerAuction().then((res) => {
        //             const sortedData = res?.data?.data?.dataList.sort((a, b) => new Date(b.date) - new Date(a.date));
        //             sortedData.reverse();
        //             setBidStatusAuction([...sortedData]);
        //         });
        //     }
        //     BidStatusAll()
        // }
    };



    return (
        <>
            <TopNavbar sidebarfun={sidebarfun} />
            <Modal size="lg" show={showView} onHide={handleClose}>
                <Form noValidate validated={validated}   >
                    <Modal.Header >
                        <Modal.Title>Resale Bid</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                                <Form.Label>Auction Name</Form.Label>
                                <Form.Control name="firstName" disabled value={auctionToSellData?.auctionName} required type="text" placeholder="Enter your first name" />
                                <Form.Control.Feedback type="invalid">
                                    Please enter First Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Team Name</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="teamName"
                                        disabled
                                        placeholder=" Enter your last name"
                                        value={auctionToSellData?.teamName}
                                        aria-describedby="inputGroupPrepend"
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Last Winning Amount</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="bidAmount"
                                        placeholder=" Enter your last name"
                                        value={auctionToSellData?.bidAmount}
                                        aria-describedby="inputGroupPrepend"
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Current OwnerShip</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        disabled
                                        name="lastName"
                                        placeholder=" Enter your last name"
                                        value={auctionToSellData?.maxOwnerShip}
                                        aria-describedby="inputGroupPrepend"
                                        readOnly
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Bid Increment </Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        disabled
                                        placeholder=" Enter Bid Increment for Sell"
                                        value={auctionToSellData?.bidIncrement}
                                        aria-describedby="inputGroupPrepend"
                                        onChange={(e) => handlebidIncrementToSell(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a bid increment.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Sell Ownership</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select onChange={handelForm} name="ownerShipSold" className='select-option' required as="select">
                                        <option value={""} >Select Ownership</option>
                                        {
                                            auctionToSellData?.maxOwnerShip == 25 ? (<>
                                                <option value={"25"}>25%</option>

                                            </>) : auctionToSellData?.maxOwnerShip == 50 ? (<>
                                                <option value={"25"}>25%</option>
                                                <option value={"50"} >50%</option>

                                            </>) : (<>
                                                <option value={"25"}>25%</option>
                                                <option value={"50"} >50%</option>
                                                <option value={"100"} >100%</option>

                                            </>)
                                        }

                                    </Form.Select >
                                    <Form.Control.Feedback type="invalid">
                                        Please select a ownership sold.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                                <Form.Label>Minimum Bid</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type="text"
                                        name="startingBid"
                                        placeholder=" Enter minimum bid to sell"
                                        required
                                        aria-describedby="inputGroupPrepend"
                                        onChange={handelForm}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a minimum bid.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md={6} className="mt-3">
                                <Form.Label>Start Bid Date and Time</Form.Label>
                                <DatePicker format="MM/dd/yyyy HH:mm aa" placeholder="Start Date"
                                    disabledDate={(date) => date < new Date().setHours(0, 0, 0, 0)}
                                    name="startDate" className=' Auction-Date'
                                    onChange={handelDate} required />
                                <Form.Control.Feedback type="invalid" style={{ display: dateValid ? 'none' : 'block' }}>
                                    Please select the date and time.
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSell}>
                            Bid Sell
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <div className='dasboard-group'>
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <h1 className="heading-dash">Secondary Market</h1>
                    <div className='group_data_table'>

                        <div className="group_data_table tabs-auction">
                            <div className="tabs-box">
                                <Tabs
                                    activeKey={key}
                                    defaultActiveKey="Sell Auctions"
                                    id="auctiontabs"
                                    className="mb-0 tab-list-item"
                                    justify
                                    onSelect={handleSelect}
                                >
                                    <Tab eventKey="Sell Auctions" title="Sell Auctions">
                                        <div className='search-input filter d-flex align-items-center'>
                                            <label className='me-3'>Search</label>
                                            <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchTextLive} onChange={handleSearchLive} />
                                        </div>
                                        <DataTable
                                            title="dfsfd"
                                            columns={column}
                                            data={filteredDataAuctionWinner}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                            paginationTotalRows={filteredDataAuctionWinner?.length}
                                        />

                                    </Tab>

                                    <Tab eventKey="profile" title="Live and Upcoming">
                                        <div>

                                            <div className="search-input filter auction-filter palyer">

                                                <Form.Group as={Col} md="6 mt-3 me-3" className="text-left  mt-2" controlId="validationCustomOption">
                                                    <Form.Label className="search-player-auction">Search</Form.Label>
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchTextUpcomingLive}
                                                        onChange={handleSearchUpcomingLive}
                                                    />
                                                </Form.Group>
                                            </div>

                                            <DataTable
                                                columns={columns}
                                                data={filterListData?.filter((event) => event.status === "Upcoming" || event.status === "Live")}
                                                pagination
                                                paginationPerPage={10}
                                                defaultSortFieldId={1}
                                                paginationRowsPerPageOptions={[10, 20, 30]}
                                            />

                                        </div>
                                    </Tab>



                                    <Tab eventKey="Closed" title="Closed">
                                        <div>
                                            <div className="auctiontabs">
                                                <div className="search-input filter auction-filter">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchTextClose}
                                                        onChange={handleSearchClose}
                                                    />
                                                </div>

                                                < DataTable
                                                    columns={columns}
                                                    // data={filteredDataCloseAuction}
                                                    data={filterListData?.filter((event) => event.status === "Closed" || event.status === "Archive")}
                                                    pagination
                                                    paginationPerPage={10}
                                                    defaultSortFieldId={1}
                                                    paginationRowsPerPageOptions={[10, 20, 30]}
                                                />
                                            </div>
                                        </div>
                                    </Tab>

                                    {/* <Tab eventKey="Secondary Market Winners" title="Secondary Market Winners">
                                        <div>
                                            <div className="auctiontabs">
                                                <div className="search-input filter auction-filter">
                                                    <input
                                                        type="text"
                                                        className="form-control mb-3 mt-3"
                                                        placeholder="Search..."
                                                        value={searchTextClose}
                                                        onChange={handleSearchClose}
                                                    />
                                                </div>

                                                < DataTable
                                                    columns={Columns_SM_Winners}
                                                    data={filteredDataSMWinners}
                                                    pagination
                                                    paginationPerPage={10}
                                                    defaultSortFieldId={1}
                                                    paginationRowsPerPageOptions={[10, 20, 30]}
                                                />
                                            </div>
                                        </div>
                                    </Tab> */}

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={data ? "Footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}

