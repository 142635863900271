import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { Store } from './redux/store/store';
import { ContextProvider } from './redux/Context';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <ContextProvider>
            <App />
        </ContextProvider>
    </Provider>
);
reportWebVitals();
