import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import * as icons from 'react-bootstrap-icons';
import { ModalBox, ModalDelete } from '../Outer/Modal';
import { SportAllMap, SportDelete, SportsReg, SportsUpdate, UploadImage } from '../../redux/auth-reducer/auth-service';
import DataTable from 'react-data-table-component';
import { Footer } from '../../Footer';
import { ToastContainer, toast } from 'react-toastify';
import { ImageUrl } from '../../utils/config/config';
import { Loader } from 'rsuite';
export const Sports = () => {
    const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageName, setImageName] = useState('');
    const [itemList, setItemList] = useState('');
    const [dataImage, setDataImage] = useState();
    const [dataImageUpdate, setDataImageUpdate] = useState();
    const [count, setCount] = useState(0);
    const [deleteRow, setDeleteRow] = useState(0);
    const [pageSize, setPageSize] = useState();
    const [imageResponse, setImageResponse] = useState();
    const [searchText, setSearchText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageAdd, setSelectedImageAdd] = useState(null);
    const [isBracket, setIsBracket] = useState(false);
    const [bracket, setBracket] = useState();
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [pageNo, setPageNo] = useState(1);
    const [err, setErr] = useState(false)
    const [editData, setEditData] = useState({
        sportImage: "",
        sportName: "",
        sportId: 0,

    });
    const [sportReg, setSportReg] = useState({
        sportName: "",
        sportImage: "",
        sportId: "",
  
    })

    function sidebarfun() {
        setData(!data)
    }
    const AddSport = () => {
        setShow(true);
    }
    const handleClosed = (e) => {
        setShowModal(false);
        setSelectedImage("")
        setSelectedImageAdd("");
        setShow(false);
        setShowModalEdit(false);
    };

    const handelSport = (e) => {

        setSportReg({ ...sportReg, [e.target.name]: e.target.value });

        if (e.target.value.length === 50) {
            setErr(true);
        }
        else {
            setErr('')
        }

    }

    useEffect(() => {
        setBracket(editData.isBracket)
    }, [editData])

    const handleFileChange = (event) => {
        setLoading(true);
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (event) {
                setSelectedImageAdd(event.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }

        const dataList = (event.target.files[0]);
        const imageAPI = async () => {
            const formDataImage = new FormData();
            formDataImage.append('file', dataList);

            await UploadImage(formDataImage).then((res) => {
                setImageResponse(res)

                if (res?.data?.status == true) {
                    setDataImage(res?.data?.message);
                    setLoading(false);

                } else {
                    toast.error(res?.message);

                }
            })
        }

        imageAPI()

    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        setValidated(true);
        if (imageResponse?.status == false) {
            toast.error("Please upload image file minimume 2mb size.");
            return null
        }
        const registerData = [{
            "crudOperationId": true,
            "sportsID": 0,
            "sportName": sportReg.sportName,
            "sportImage": dataImage || "",
            // "userId": 2,
            "isBracket": isBracket
        }]

        await SportsReg(registerData).then((res) => {
            console.log(res, "res");
            if (res?.data?.status == true) {
                setSelectedImageAdd("")
                setCount(count + 1);
                setShow(false);
                toast.success(res?.data?.message);
                setValidated(false);
                setDataImage('')
                setSportReg({
                    sportsName: "",
                    imageName: "",
                    sportsId: "",
                    userId: 0
                })
            }
            else {
                toast.error(res?.data?.message);
            }
        })

    };


    const SportAllFn = async (data) => {
        await SportAllMap(data).then((response) => {
            setItemList(response?.data?.data?.dataList);
            setPageSize(response?.data?.data?.totalPageSize);

        })
    }

    useEffect(() => {
        const data = {
            "PageNumber": 1,
            "PageSize": perPage
        }
        SportAllFn(data);

    }, [count]);






    const columns = [
        {
            name: <strong>Id</strong>,
            selector: (row, index) => index + 1,

            width: "80px",
        },

        {
            name: <strong>Logo</strong>,
            selector: (row) => (
                <>
                    {row.sportImage == "" ? (
                        <img src="../image/sport-dummy.jpg" width={100} />
                    ) : (
                        <img
                            src={ImageUrl + row?.sportImage}
                            width={100}
                            className="img-fluid p-3"
                        ></img>
                    )}
                </>
            ),
            sortable: true,
        },
        {
            name: <strong>Sport Name</strong>,
            selector: (row) => row?.sportName,
            sortable: true,
        },

        {
            name: <strong>Action</strong>,
            selector: (row) => (
                <>
                    <button
                        type="button"
                        className="text-danger btn-aucion  ms-1"
                        onClick={() => deleteFn(row?.sportId)}
                    >
                        <icons.Trash />
                    </button>
                    <button
                        type="button"
                        className="text-success btn-aucion ms-1"
                        onClick={() => edit(row)}
                    >
                        <icons.PencilSquare />
                    </button>
                </>
            ),
            sortable: true,
            width: "200px",
        },
    ];

    const handelEditSport = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
        if (e.target.value.length === 50) {
            setErr(true);
        }
        else {
            setErr('')
        }
    }
    const handelBracket = (event) => {
        setIsBracket(event.target.checked);

    }
    console.log(isBracket, "dfssfd")

    const edit = async (edititem) => {
        setShowModalEdit(true);

        setSelectedImageAdd(edititem?.imageName);
        setEditData(edititem)
        setDataImageUpdate(edititem?.imageName);
    }





    const deleteFn = async (sportId) => {

        setDeleteRow(sportId);
        setShowModal(!showModal);
    }




    const update = async () => {

        const registerData = imageName ? {
            "crudOperationId": false,
            "sportId": editData?.sportId,
            "sportName": editData?.sportName,
            "sportImage": dataImageUpdate || "",
        
            "isBracket": isBracket
        } : {
            "crudOperationId": false,
            "sportId": editData?.sportId,
            "sportName": editData?.sportName,
            "sportImage": dataImageUpdate || "",

            "isBracket": isBracket
        }

        await SportsUpdate(registerData).then((res) => {
            if (res?.status == true) {

                setCount(count + 1);
                setShowModalEdit(false);
                setSelectedImage('');
                toast.success(res?.message);
            }

            else {
                toast.error(res?.message);
            }
        })

    }

    const handelDelete = async () => {

        await SportDelete(deleteRow).then((res) => {
            if (res?.status == true) {
                setShowModal(!showModal);
                setCount(count + 1);
                toast.success("Delete Sport Successfully");
            } else {

                toast.error(res?.message)
                setShowModal(!showModal);
            }
        })
    }

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = searchText ? itemList.filter((item) => {
        return item.sportsName.toLowerCase().includes(searchText.toLowerCase())

    }) : itemList;

    const handelImage = (e) => {

        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
        setSelectedFile(e.target.files[0].name)
        setImageName(e.target.files[0].name)
        const dataList = (e.target.files[0]);
        const imageAPI = async () => {
            const formDataImage = new FormData();
            formDataImage.append('file', dataList);
            await UploadImage(formDataImage).then((res) => {

                if (res?.data?.status == true) {
                    setDataImageUpdate(res?.data?.message)


                } else {

                }
            })
        }

        imageAPI()
    }



    return (

        <>

            <ModalBox show={showModalEdit} onHide={handleClosed} title="Update Sports" body={
                <>
                    <input type='hidden' className='form-control'></input><br />
                    <input type='text' className='form-control' maxLength={50} name="sportName" onChange={handelEditSport} value={editData?.sportName} required ></input><br />
                    {err ? <p style={{ color: '#dc3545' }}>Sport shouldn't exceed 50 characters</p> : ""}
                    <input type='file' className='form-control' name="sportImage" onChange={handelImage}  ></input><br />

                    {selectedImage ? <img src={selectedImage} alt="Selected" width={100} /> : <img src={ImageUrl + editData?.sportImage} width={100}></img>}

                    <Form.Group>
                        <label className="d-flex  mt-3">
                            <input
                                type="checkbox"
                                className="inputCheck"
                                onClick={handelBracket}
                                name="isBracket"
                                checked={bracket}
                                disabled={true}
                            />
                            {
                                editData.isBracket === true ? "There is Bracket in this Sport." : "There is no Bracket in this Sport."
                            }
                            {/* Do you want to add tournament bracket. */}
                        </label>
                    </Form.Group>
                </>
            } footer={
                <>
                    <Button variant="primary" className="btn-Save me-3" onClick={update}>Update</Button>
                    <Button type="button" className='btn-Cancel secondary' onClick={handleClosed} >Cancel</Button>

                </>
            } />

            <ModalDelete show={showModal} onHide={handleClosed} title="Delete Sports" body={
                <>
                    <p>Are you want to delete the sport?</p>

                </>
            } footer={
                <>
                    <Button variant="danger" onClick={handelDelete}>Delete</Button>
                    <Button variant="secondary" onClick={handleClosed} >Close</Button>

                </>
            } />

            <ModalBox show={show} onHide={handleClosed} title="Add Sport" body={
                <>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Sport Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="sportName"
                                    maxLength={50}
                                    onChange={handelSport}
                                    placeholder="Sport Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid sport name.
                                </Form.Control.Feedback>
                                {err ? <p style={{ color: '#dc3545' }}>Sport shouldn't exceed 50 characters</p> : ""}
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Logo</Form.Label>
                                <Form.Control

                                    type="file"
                                    name="sportImage"
                                    onChange={handleFileChange}
                                    placeholder="Choose file"
                                />

                                {loading && <Loader />}

                                {dataImage ? <img src={ImageUrl + dataImage} alt="Selected" width={100} /> : ""}

                                <p className='mt-2 image-upload-mess'>(jpeg, jpg, png and size upto 2 mb)<span className='text-danger'>*</span></p>

                            </Form.Group>
                            <Form.Group>
                                <label className="d-flex  mt-3">
                                    <input
                                        type="checkbox"
                                        className="inputCheck"
                                        onClick={handelBracket}
                                        name="isBracket"
                                        value={isBracket}
                                    />

                                    Do you want to add contest bracket.
                                </label>
                            </Form.Group>

                        </Row>
                        <Row>
                            <Col md={6}></Col>
                            <Col md={6} className='text-right'> <Button type="submit" className='btn-Save me-3'>Save</Button>
                                <Button type="button" className='btn-Cancel secondary' onClick={handleClosed} >Cancel</Button>
                            </Col>
                        </Row>

                    </Form>

                </>
            } />

            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <Row>
                        <Col md={6}><h1 className="heading-dash">Sports</h1></Col>
                        <Col md={6} className='text-right'><Button className='primary button-create-auction btn btn-primary ' onClick={AddSport}><icons.Plus class="icon-plus" />  Add Sport</Button></Col>

                    </Row>

                    <div className='group_data_table sport'>
                        <div className='search-input d-flex align-items-center'>
                            <label className='me-3'>Search</label>
                            <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                        </div>
                        <DataTable
                            title="Sport List"
                            columns={columns}
                            data={filteredData}

                            pagination
                            paginationRowsPerPageOptions={[10, 20, 30]}
                            paginationTotalRows={filteredData?.length}

                        />


                    </div>
                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>

        </>
    )
}
