import React from 'react'
import Headertwo from './Headertwo'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import Footertwo from './Footertwo'

export const Faq = () => {
    return (
        <>
            <div className='group-section'>
                <Headertwo />
                <div className='banner-item-Faq'>
                 
                </div>
            </div>
            <section className='p-5 about-us-page Faq-sec'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md="12">

                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Is Xcutta a gambling site?</Accordion.Header>
                                    <Accordion.Body>
                                        No, Xcutta is the world’s most powerful sports auction software. It is a subscription
                                        based live auction, data collection, and accounting software designed specifically for
                                        sports fanatics. Money or winnings from auctions within this software is neither
                                        collected nor distributed by Xcutta. Xcutta software is designed for entertainment
                                        purposes only and gambling on the site is strictly prohibited.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How do I play Xcutta?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        Xcutta is simple. You may play as a Manager or as a Player or as both. The Manager
                                        possesses the power to set up customized auctions and winnings. The Player
                                        participates in auctions that he is invited into by a Xcutta Manager.
                                    </Accordion.Body>
                                </Accordion.Item>
                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header>What sports can I use in Xcutta?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Any sport in the world can be adapted to the Xcutta format.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                    How does the Xcutta Auction work?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    The Xcutta Manager sets up the auction with a start date and time. The next step is to 
                                    invite his Xcutta players to the auction with the notification tool in the Xcutta software. 
                                    The auction works off predetermined settings chosen by the Xcutta manager. The live 
                                    auction will sell one team at a time with a bid time limit. The bidding on each team 
                                    ends when the bid timer expires and no further bids have been made by the 
                                    participating Xcutta Players. The last highest bidding Player wins the team in the 
                                    auction. Once completed, the bidding begins for the next team in the auction. This 
                                    continues until the auction is completed.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                    Can a Manager bid in an auction? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    No, a Xcutta Manager can only create and manage auctions. A Manager can not bid 
                                    on a team in any Xcutta Auction.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                    Can I sign up as a Manager and Player?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Yes. A person may sign up as a Manager to create an auction and also sign up as a 
                                    Player to participate in any auction. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                    Why can’t a Manager bid in an auction?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    A Manager account is strictly designed for creating and managing auctions. The 
                                    Manager account portal contains powerful auction data collection tools privy only to 
                                    the Manager.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                    Can I create more than one auction under my Manager account?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Yes, a Xcutta Manager has the ability to create an unlimited amount of auctions within 
                                    his one account.

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>
                                    Can I create custom auctions as a Xcutta Manager?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Yes, the Xcutta Manager has the power to place as many teams, players, or drivers in 
                                    the auction as he deems necessary. 
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>
                                    Can I play Xcutta with my local golf course players?
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Yes, Xcutta gives the Manager the ability to create his own sport for customized 
                                    players. For example, a Xcutta Manager can create Local Golf Course as the sport and 
                                    enter each individual player’s name into the software as a team. These players can 
                                    then be entered into a customized Xcutta Auction by the Manager.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>
                                    What is “The Field”? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    The Field represents all other teams, players, or drivers not included in an auction. The 
                                    Xcutta Manager will typically put the Field in his auction for bid. For example, a Xcutta 
                                    auction for the 64 team NCAA basketball contest may include the top 24 teams 
                                    and The Field which, in this scenario, would be the remaining 40 teams auctioned off
                                    as one team.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                    <Accordion.Header>
                                    Can I sign up as a Player and participate in any Auction? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    You may sign up as a Player anytime, however, a Player may only participate in 
                                    Auctions that he or she is invited into by the Xcutta Manager. A Player may also 
                                    participate in any public secondary market.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                    <Accordion.Header>
                                    What is the Secondary Market? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    A secondary market is created at the conclusion of each auction and is dedicated 
                                    specifically to that closed auction. The Xcutta Manager of each auction has the ability 
                                    to declare public or private access to the secondary market. Once the secondary 
                                    market opens, a Player may choose to sell 25%, 50% or 100% ownership of any team 
                                    he won from the connected auction.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                    <Accordion.Header>
                                    Can a Player participate in a Secondary Market that he or she was not an invited
                                    participant in that connected auction? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Yes, if the Manager has chosen to make the secondary market accessible to the public.
                                    No, if the Manager has chosen to make the secondary market only accessible to his 
                                    private players.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                    <Accordion.Header>
                                    Will Xcutta share my data or personal information? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    No, Xcutta will not share any data or personal information of it’s subscribers or past 
                                    subscribers with any outside parties.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                    <Accordion.Header>
                                    Can I cancel my subscription anytime? 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    Yes, a Manager or Player may cancel his or her subscription at any time.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                    <Accordion.Header>
                                    If I cancel my subscription, will my Xcutta data be lost if I decide to subscribe at a
                                    later date. 
                                    </Accordion.Header>
                                    <Accordion.Body>
                                    A Manager or Player’s data will be saved under the user name and password of that 
                                    subscriber, so a Manager or Player may subscribe and unsubscribe without losing the 
                                    data in the Xcutta software.
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                            
                        </Col>
                        {/* <Col md="6">
                            <img src='../image/gallery4.jpg' className='img-fluid rounded-3' />
                        </Col> */}
                    </Row>

                </Container>
            </section>

            <div className='banner-item-Faq2 footer'>
                 
                </div>

            <Footertwo />
        </>



    )
}
