import React from 'react'

export const Footer = () => {
    
    return (
        <div className='footer text-center'>
            <hr></hr>
            <div className='fw-500'>Copyright &copy; 2025 XCUTTA. All Rights Reserved</div>
        </div>
    )
}
