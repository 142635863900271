import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { RefreshToken } from "./auth-reducer/auth-service";
import { SignalRUrl } from "../utils/config/config";
import { useSelector } from "react-redux";
import { SetRefreshToken, SetToken } from "../utils/helper/helper";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
  const [hubConnection, setHubConnection] = useState(0);
  const { authtoken } = useSelector((state) => state.auth)

  useEffect(() => {
    if(authtoken){
      
    const createHubConnection = async () => {
      let token = authtoken;
      const hubConnect = new signalR.HubConnectionBuilder()
        .withUrl(SignalRUrl, {
          // https://gadienterprises-gamc-qa.chetu.com/hubs
          // https://staging.xcutta.com/backend/hubs
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .build();

      const handleTokenExpiration = async () => {
        try {
          const newToken = await RefreshToken(); // Assume this function returns a new token

          hubConnect.accessTokenFactory = () => token; // Update token factory
        } catch (error) {
   
        }
      };

      try {
        await hubConnect.start(); // Try to start the connection
      } catch (err) {
        // Attempt to refresh token and retry connection
        await handleTokenExpiration();
        try {
          await hubConnect.start(); // Retry starting the connection
           } catch (retryErr) {
         
        }
      }

      // Handle closed connections
      hubConnect.onclose(async (error) => {
          await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds before reconnection
        createHubConnection(); // Try to reconnect
      });

      setHubConnection(hubConnect);
    };

    createHubConnection();

  }
    // Cleanup function to stop the connection when the component unmounts
    return () => {
      if (hubConnection) {
        hubConnection.stop()
          .then(() => console.log("SignalR Connection Stopped"));
      }
    };
  }, [authtoken]);

  return (
    <Context.Provider value={{ hubConnection, setHubConnection }}>
      {children}
    </Context.Provider>
  );
};