import React, { useEffect, useState } from 'react'
import { Footer } from '../../Footer'
import DataTable from 'react-data-table-component'
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import * as icons from 'react-bootstrap-icons';
import { SportAllMap, TeamGetAll, TeamGetBy, TournamentAll, TournamentBarcketId, TournamentDelete, TournamentUpdate, UploadImage } from '../../redux/auth-reducer/auth-service'
import { ImageUrl } from '../../utils/config/config'
import { ModalBox, ModalDelete } from '../Outer/Modal'
import { ToastContainer, toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { MultiSelect } from 'react-multi-select-component'
import { DatePicker } from 'rsuite';
import dayjs from 'dayjs'

export const Tournament = () => {
    const [data, setData] = useState();
    const [validated, setValidated] = useState(false);
    const [itemList, setItemList] = useState('');
    const [splitedTeamID, setSplitedTeamID] = useState([]);
    const [count, setCount] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRow, setDeleteRow] = useState()
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showEdit, setShowEdit] = useState();
    const [showView, setShowView] = useState(false);
    const [selectedSport, setSelectedSport] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dataImageUpdate, setDataImageUpdate] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [tournamentImage, setTournamentImage] = useState()
    const [error, setError] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [sportIdData, setSportIdData] = useState();
    const [selectedSportId, setSelectedSportId] = useState([]);

    const navigate = useNavigate();
    function sidebarfun() {
        setData(!data)
    }
    const [editData, setEditData] = useState({
        tournamentName: "",
        tournamentImage: "",
        startDate: "",
        endDate: "",
        minBid: "",
        bidIncrement: "",
        fK_SportId: "",
        TeamId: ""
    });

    useEffect(() => {

        const TournamentAllFn = async () => {
            await TournamentAll().then((response) => {

                setItemList(response?.data?.data?.dataList);

            })
        }
        TournamentAllFn();

    }, [count])




    useEffect(() => {
        const TeamGet = async () => {
            await TeamGetAll().then((res) => {

                setSelectedTeam(res?.data?.data?.dataList);

            })
        }
        TeamGet()
    }, [])

    const convertUTCToLocalTime = (utcDateString) => {
        const date = new Date(utcDateString);
        return date.toLocaleString();
    };

    const columns = [
        {
            name: <strong>Id</strong>,
            selector: (row, index) => index + 1,

        },

        {
            name: <strong> Logo</strong>,
            selector: (row) => (
                <>
                    {row?.tournamentImage == "" ? (
                        <img src="../image/TournmentImage.jpg" width={50} />
                    ) : (
                        <img
                            src={ImageUrl + row?.tournamentImage}
                            className="m-2"
                            width={50}
                        />
                    )}
                </>
            ),
            sortable: true,
        },
        {
            name: <strong>Contest Name</strong>,
            selector: (row) => row?.tournamentName,
            sortable: true,
        },

        {
            name: <strong>Sport Name</strong>,
            selector: (row) => row?.sportName,
            sortable: true,
        },

        {
            name: <strong>Start Date</strong>,
            selector: (row) => dayjs(row?.startDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>Start Time</strong>,
            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.startDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: <strong>End Date</strong>,
            selector: (row) => dayjs(row?.endDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>End Time</strong>,
            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.endDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
        {
            name: <strong>Team</strong>,
            selector: (row) => (
                <>
                    <button
                        type="button"
                        className="btn btn-primary viewButton"
                        onClick={() => viewFn(row)}
                    >
                        {" "}
                        <icons.Eye /> Team
                    </button>
                </>
            ),

            sortable: true,
        },
        {
            name: <strong>Action</strong>,
            selector: (row) => (
                <>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => createBracket(row?.tournamentId)}
                    >
                        Show Bracket
                    </Button>
                    <button
                        type="button"
                        className="text-danger btn-aucion"
                        onClick={() => deleteFn(row?.tournamentId)}
                    >
                        <icons.Trash />
                    </button>
                    <button
                        type="button"
                        className="text-success btn-aucion"
                        onClick={() => editFn(row)}
                    >
                        <icons.PencilSquare />
                    </button>
                </>
            ),
            sortable: true,
            width: "250px",
        },
    ];
    const createBracket = async (tournamentId) => {

        Bracketfn(tournamentId)

    }
    
    const Bracketfn = async (tournamentId) => {
        await TournamentBarcketId(tournamentId).then((res) => {
            navigate(`/SuperAdmin/SetupBracket/${tournamentId}`, {
                state: res,
            });

        })
    }


    const deleteFn = (tournamentId) => {
        setShowDeleteModal(true);
        setDeleteRow(tournamentId)
    }

    const viewFn = (viewItem) => {

        setEditData(viewItem, "fgddfh")
        setShowView(true);
        let arrayID = viewItem?.teamId?.split(',').map(Number)

        setSplitedTeamID(arrayID)

    }

    const editFn = (editList) => {
        setShowEdit(true);
        setEditData({ ...editList });
      }

    var listItem = selectedSportId ? selectedSportId.map((list) => {
        return { label: list.teamName, value: list.teamId }
    }) : [];
    const options = [
        ...listItem,
    ];

console.log(editData?.teamId, editData?.teamId?.split(",").map(Number),"fgddgg",listItem,selectedOptions)

    useEffect(() => {
        if (selectedOptions.length === 0) {
            let data = options.filter((item)=> editData?.teamId.split(",").map(Number).includes(item?.value));

            setSelectedOptions(data);
  }
    }, [selectedSportId]);






    const handelDelete = async () => {
        await TournamentDelete(deleteRow).then((res) => {
            if (res?.status == true) {
                setCount(count + 1);
                toast.success(res?.message);
                setShowDeleteModal(!showDeleteModal);
            } else {
                toast.error(res?.message)
            }

        })
    }

    const handelselect = (e) => {
    
        setSelectedOptions(e)
        setEditData({
            ...editData,
            TeamId: e
        })
        setError('')
    }



    const handelFrom = (e) => {

        setEditData({
            ...editData,
            [e.target.name]: e.target.value
        }
        )
    }

    const handelFromSport = (e) => {
        setSportIdData(e.target.value);

        setEditData({
            ...editData,
            [e.target.name]: e.target.value
        }
        )
    }

    useEffect(() => {
        const SportGetTeam = async () => {
            await TeamGetBy(sportIdData).then((res) => {
               console.log(res,"dsaff")

                setSelectedSportId(res);
            });
        };
        SportGetTeam();
    }, [sportIdData]);



    const handelStartDate = (date) => {
        setEditData({
            ...editData,
            startDate: date
        }
        )

    }

    const handelEndDate = (date) => {
        setEditData({
            ...editData,
            endDate: date
        }
        )


    }

    useEffect(() => {
        if (editData?.endDate) {
            setEndDate(new Date(editData?.endDate));
        }
    }, [editData]);

    useEffect(() => {
        if (editData?.startDate) {
            setStartDate(new Date(editData?.startDate));
        }
    }, [editData]);

    const handleClosed = () => {
        setShowDeleteModal(false);
        setShowView(false);
        setSelectedImage("")
        setShowEdit(false);
    };

    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = searchText
        ? itemList.filter((item) => {
            return (
                item?.tournamentName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                item.sportName?.toLowerCase().includes(searchText?.toLowerCase())
            );
        })
        : itemList;

    useEffect(() => {
        const SportGet = async () => {
            await SportAllMap().then((res) => {

                setSelectedSport(res?.data?.data?.dataList);
            })
        }
        SportGet()
    }, [])

    const handelImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();
            reader.onload = function (e) {

                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
        setSelectedFile(e.target.files[0])
        setTournamentImage(e.target.files[0].name)

        const dataList = (e.target.files[0]);
        const imageAPI = async () => {
            const formDataImage = new FormData();
            formDataImage.append('file', dataList);
            await UploadImage(formDataImage).then((res) => {
                if (res?.data?.status) {

                    setDataImageUpdate(res?.data?.message);

                } else {

                }
            })
        }

        imageAPI()
    }




    useEffect(() => {

        const SportGetTeam = async () => {
            await TeamGetBy(editData?.sportId).then((res) => {

                setSelectedSportId(res);
            });
        };
        SportGetTeam();

    }, [editData])



    const handleUpdate = async (e) => {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget;
        if (selectedOptions?.length > 0) {
            setError('')
        }
        else {
            return setError('Please select at least one option');
        }
        // if (form.checkValidity() == true) {
        //     var TeamId = editData?.TeamId?.map((ele) => {
        //         return ele.value;
        //     })
        //     var teamid = TeamId;
        // }
        
       var teamid =selectedOptions?.map((item)=>item?.value)

        const registerData = tournamentImage ? {
            "crudOperationId": false,
            "tournamentId": editData?.tournamentId,
            "tournamentName": editData?.tournamentName,
            "tournamentImage": dataImageUpdate,
            "startDate": editData?.startDate,
            "endDate": editData?.endDate,
            "minBid": editData?.minBid,
            "bidIncrement": editData?.bidIncrement,
            "sportId": editData?.sportId,
            "teamId": teamid
        } : {
            "crudOperationId": false,
            "tournamentId": editData?.tournamentId,
            "tournamentName": editData?.tournamentName,
            "tournamentImage": editData?.tournamentImage,
            "startDate": editData?.startDate,
            "endDate": editData?.endDate,
            "minBid": editData?.minBid,
            "bidIncrement": editData?.bidIncrement,
            "sportId": editData?.sportId,
            "teamId": teamid
        }

        await TournamentUpdate(registerData).then((res) => {
            if (res?.status == true) {
                toast.success(res?.message);
                setShowEdit(false);
                setCount(count + 1);
            }
            else {
                toast.error(res?.message);
            }
        })
        setSelectedOptions("");
    }
    const disabledDate = (date) => {
        return date < startDate;
    };

    return (
        <>

            <ModalDelete show={showDeleteModal} title="Delete Contest" body={
                <>
                    Are you want to delete the Contests?
                </>
            } footer={
                <>
                    <Button variant="danger" onClick={handelDelete} >Delete</Button>
                    <Button variant="secondary" onClick={handleClosed} >Close</Button>

                </>
            } />

            <ModalBox show={showView} title={<><h1 className="heading-dash">View Team</h1></>} body={
                <>

                    <Table bordered>
                        <thead >
                            <tr >
                                <td className='bg-gray-view'>Logo</td>
                                <td className='bg-gray-view'>Team Name</td>
                            </tr>
                        </thead>
                        <tbody>

                            {splitedTeamID?.map((item) =>
                                selectedTeam?.map((value) => {
                                    if (item === value?.teamId) {
                                        return (
                                            <tr key={value.teamId}>
                                                <td>
                                                    <img
                                                        src={value?.teamImage ? ImageUrl + value?.teamImage : "../image/team-icon.png"}
                                                        width="50"
                                                        alt={value?.teamName}
                                                    />
                                                </td>
                                                <td>{value.teamName}</td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })
                            )}

                        </tbody>
                    </Table>

                </>
            } footer={
                <>
                    <Button variant="secondary" onClick={handleClosed} >Close</Button>

                </>
            } />

            <ModalBox show={showEdit} onHide={handleClosed} title="Update Contest" body={
                <>
                    <Form noValidate validated={validated} onSubmit={handleUpdate} >
                        <Row className="mb-3">

                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Logo Image</Form.Label>
                                <input type='file' className='form-control mb-2' name="imageName" onChange={handelImage}  ></input>


                                {selectedImage ? <img src={selectedImage} alt="Selected" width={100} /> : <img src={ImageUrl + editData?.tournamentImage} width={100}></img>}




                            </Form.Group>

                            <Form.Group validated as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Contest Name</Form.Label>
                                <Form.Control type='text' className='form-control' name="tournamentName" value={editData?.tournamentName} required onChange={handelFrom} />
                                <Form.Control.Feedback type="invalid">
                                        Please enter the contest name.
                                    </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12 mt-3" className="text-left " controlId="validationCustomCountry">
                                <Form.Label>Sports</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Select name="sportId" value={editData?.sportId} onChange={handelFromSport} className='select-option select-sport' required as="select">
                                        <option value={''}>Select Sports</option>
                                        {selectedSport?.map((listItem) =>
                                            <option key={listItem?.sportID} value={listItem?.sportId}>{listItem?.sportName}</option>
                                        )}
                                    </Form.Select >

                                    <Form.Control.Feedback type="invalid">
                                        Please a select sport name.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md="12 mt-2" controlId="validationCustom02">
                                <Form.Label>Start Date</Form.Label>

                                <DatePicker

                                    format="MM/dd/yyyy HH:mm aa"
                                    value={startDate}
                                    name="startDate"
                                    onChange={handelStartDate}
                                    disabledDate={(date) => date < new Date()}
                                    required
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please select start date.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12 mt-3" controlId="validationCustom02">
                                <Form.Label>End Date</Form.Label>
                                <DatePicker

                                    format="MM/dd/yyyy HH:mm aa"
                                    value={endDate}
                                    name="endDate"
                                    onChange={handelEndDate}
                                    disabledDate={disabledDate}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select end date.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12 mt-3" className="text-left mt-2" controlId="validationCustomOption">
                                <Form.Label>Team</Form.Label>



                                <MultiSelect options={options} value={selectedOptions || []}
                                    name="teamId" onChange={handelselect} labelledBy={"Select"} required as="select"  />

                                <Form.Control.Feedback type="invalid">
                                    Please a select team.s
                                </Form.Control.Feedback>
                                {error && <div style={{ color: '#dc3545' }}>{error}</div>}
                            </Form.Group>
                        </Row>

                        <Button type="submit" className='btn-Save me-3'>Update</Button>
                        <Button type="button" className='btn-Cancel secondary' onClick={handleClosed}>Cancel</Button>
                    </Form>
                </>
            }

            />

            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
                    <Row>
                        <Col md={6}><h1 className="heading-dash">Contest</h1></Col>
                        <Col md={6} className='text-right'>
                            <Link to="/SuperAdmin/CreateContest"> <Button className='primary button-create-auction'><icons.Plus class="icon-plus" /> Create Contest</Button></Link>
                        </Col>

                    </Row>

                    <div className='group_data_table tournament-list'>
                        <div className='search-input d-flex align-items-center'>
                            <label className='me-3'>Search</label>
                            <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                        </div>
                        <DataTable
                            title="Contest List"
                            columns={columns}
                            data={filteredData}
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 20, 30]}
                            paginationTotalRows={filteredData?.length}
                        />
                    </div>


                </div>
                <div className={data ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )
}
