import React, { useEffect, useState } from "react";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import { Footer } from "../../Footer";
import { Button, Col, Form, Row } from "react-bootstrap";
import * as icons from "react-bootstrap-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DataTable from "react-data-table-component";
import { ModalBox } from "../Outer/Modal";
import { AdminReg, AdminGet, ManagerGetById } from "../../redux/auth-reducer/auth-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

export const Admin = () => {
  const [searchText, setSearchText] = useState("");
  const [showView, setShowView] = useState(false);
  const [adminDeatils, setAdminDeatils] = useState();
  const [dataSide, setDataSide] = useState();
  const [validated, setValidated] = useState(false);
  const [userData, setUserData] = useState("");
  const [count, setCount] = useState(0);
    const [countryCode, setCountryCode] = useState("");
  const [data, setData] = useState({
    managerEmailId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    password: "",
    adminPassword: "",
    countryCode: null,
  });

  function sidebarfun() {
    setDataSide(!dataSide)
  }


  const handelPhoneCountryCode = async (e) => {
  
    setCountryCode(e);
    setData({
      ...data,
      countryCode: e,
    });
  };
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,

    },
    {
      name: "First Name",
      selector: (row) => row?.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row?.lastName,
      sortable: true,
    },
    {
      name: "Email Address",
      selector: (row) => row?.emailId,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row?.phoneNumber,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row) =>  <>{row?.orgName==null?"-": row?.orgName}</>,
      sortable: true,
    },

  ];

  const viewFn = (viewItem) => {
    setShowView(true);
  };
  const handleClosed = () => {
    setShowView(false);
    setValidated(false);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = searchText
    ? adminDeatils.filter((item) => {
      return (
        item?.firstName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.lastName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.orgName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.emailId?.toLowerCase().includes(searchText?.toLowerCase())
      );
    })
    : adminDeatils;

  const isUser = JSON.parse(localStorage.getItem('isUser'));
  const userId = isUser?.userId;
  useEffect(() => {
    const UserGet = async () => {
      await ManagerGetById(userId).then((res) => {

        setUserData(res?.data);

      });
    };
    UserGet();

  }, [])

  const handleChange = (e) => {
    // e.preventDefault();
    // let value = e.target.value;
    // let name = e.target.name;
    // setData((prevalue) => {
    //   return {
    //     ...prevalue,
    //     [name]: value,
    //   };
    // });
    setData({
      ...data,

      [e.target.name]: e.target.value
    }
    )
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    // Check for blank fields
    // if (!data?.firstName || !data?.lastName || !data?.emailId || !data?.phoneNumber || !data?.password || !data?.adminPassword) {
    //   setValidated(true);
    //   return; // Stop execution if any required field is blank
    // }
    var mailformat = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const dataRegister = [
      {
        // crudOperationId: true,
        id: 0,
        // managerEmailId: userData?.emailId,
        // orgId: userData?.managerId,
        firstName: data?.firstName,
        lastName: data?.lastName,
        emailId: data?.emailId,
        phoneNumber: data?.phoneNumber,
        password: data?.password,
        countryCode: Number(data?.countryCode),
        managerPassword: data?.adminPassword,
      },
    ];

  console.log(dataRegister,"dataRegister")


    if (data?.emailId?.match(mailformat)) {

      await AdminReg(dataRegister).then((res) => {

        if (res?.data?.status === true) {
          toast.success(res?.data?.message);
          setCount(count + 1);
          setValidated(false);
          handleClosed();
        } else {
          toast.error(res?.data?.message);
  
        }
  
  setData({
    managerEmailId: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    password: "",
    adminPassword: "",
  })
  
      });
    }




   
  };

  const getAdminData = async () => {
    await AdminGet().then((res) => {
      setAdminDeatils(res?.data?.data?.dataList, "res");
    });
  };

  useEffect(() => {
    getAdminData();
  }, [count]);

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="dasboard-group">
        <TopNavbar sidebarfun={sidebarfun} />
        <Sidebar data={dataSide} />
        <div
          className={
            dataSide ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >

          <Row className="d-flex align-items-center">
            <Col md={6}>
              <h1 className="heading-dash">Admin</h1>
            </Col>
            <Col md={6} className="text-right">
              <Button
                className="primary button-create-auction btn btn-primary "
                onClick={() => viewFn()}
              >
                <icons.Plus class="icon-plus" /> Add Admin
              </Button>
            </Col>
          </Row>

          <div className="group_data_table admin">
            <div className="search-input d-flex align-items-center">
              <label className="me-3">Search</label>
              <input
                type="text"
                className="form-control mb-3 mt-3"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
              />
            </div>
            <DataTable
              title="Admin"
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}

            />
          </div>
        </div>
        <div className={"footer-content"}>
          <Footer />
        </div>
      </div>

      <ModalBox
        show={showView}
        title={
          <>
            <h1 className="heading-dash">Add Admin</h1>
          </>
        }
        body={
          <>
            <Form noValidate validated={validated}>
              <Row>
                <Col>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="firstName"
                      value={data?.firstName}
                      onChange={(e) => handleChange(e)}
                      placeholder="First name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid first name.
                    </Form.Control.Feedback>
                  </Form.Group>

                </Col>
                <Col>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="lastName"
                      value={data?.lastName}
                      onChange={(e) => handleChange(e)}
                      placeholder="Last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid last name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      name="emailId"
                      value={data?.emailId}
                      onChange={(e) => handleChange(e)}
                      placeholder="Email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid email.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
         

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Phone Number</Form.Label>
                    <div className="d-flex group-ph">
                    <PhoneInput
                          type="text"
                          international
                          value={countryCode || `+${data?.countryCode}`}
                          onChange={handelPhoneCountryCode}
                          flags={flags}
                          className="phoneAdmin"
                          countryCallingCodeEditable={false}
                        />

                    <Form.Control
                      required
                      type="number"
                      name="phoneNumber"
                      className="phone-number-input"
                      value={data?.phoneNumber}
                      onChange={(e) => handleChange(e)}
                      placeholder="Phone number"
                    />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please enter valid phone number.
                    </Form.Control.Feedback>
                  </Form.Group>




                </Col>
              </Row>

              <Row>

                <Col>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Xcutta Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="password"
                      value={data?.password}
                      onChange={(e) => handleChange(e)}
                      placeholder="Password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>

                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Temporary Password for Admin</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      name="adminPassword"
                      value={data?.adminPassword}
                      onChange={(e) => handleChange(e)}
                      placeholder="Temporary password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid password.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} className="text-right">
                  <Button
                    type="button"
                    className="btn-Cancel secondary mb-3 me-3"
                    onClick={handleClosed}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="btn-Save me-3 mb-3"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Col>
              </Row>

            </Form>
          </>
        }

      />
    </>
  );
};
