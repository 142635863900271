import axios from "axios";
import { BASE_URL, REFRESH_TOKEN_API } from "./config";
import { GetRefreshToken, GetToken, SetRefreshToken, SetToken } from "../helper/helper";
import { RefreshToken } from "../../redux/auth-reducer/auth-service";
import { toast } from "react-toastify";


axios.interceptors.request.use(
    config => {
        const accessToken = GetToken();
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
              const response = await RefreshToken();
          
                return axios(originalRequest, response);
            } catch (error) {
                localStorage.setItem("TokenInvalid", true);
                console.log("Error during token refresh:", error);
       
            }
        } else if (error?.response?.status === 400) {
            if (error?.response?.data.message === "Invalid client request.") {
                toast.error("Session has timeout. Please login");
          
                SetToken(null);
                SetRefreshToken(null);
                         window.location.href = "/login"
                localStorage.setItem("TokenInvalid", true);
            }
        }
        return Promise.reject(error);
    }
);