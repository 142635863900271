import React, { useState, useEffect } from "react";
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar";
import Sidebar from "../../CommonComponent/sidebar/Sidebar";
import DataTable from "react-data-table-component";
import { ExpandedComponent } from "./ExpandedComponent";
import { Footer } from "../../Footer";
import { Col, Row } from "react-bootstrap";
import { GetAnnouncementGetAllAuction, AnnouncementData } from "../../redux/auth-reducer/auth-service";
import dayjs from "dayjs";

export const Announcement = () => {
  const [data, setData] = useState();
  const [searchText, setSearchText] = useState("");
  const [announcements, setAnnouncements] = useState([]);
  function sidebarfun() {
    setData(!data);
  }
  const columns = [
    {
      name: <strong>Auction Name</strong>,
      selector: (row) => row?.auctionName,
      sortable: true,
    },


    {
      name: <strong>Sport Name</strong>,
      selector: (row) => row?.sportName,
      sortable: true,
    },
    {
      name: <strong>Total Pool</strong>,
      selector: (row) => row?.totalPoolAmount,
      sortable: true,
    },
    {
      name: <strong>Manager Hold </strong>,
      selector: (row) => row?.xcuttaAmount,
      sortable: true,
    },
    {
      name: <strong>Net Pool</strong>,
      selector: (row) => row?.netPoolAmount,
      sortable: true,
    },
    {
      name: <strong> Date Of Auction End</strong>,
      selector: (row) => dayjs(row?.endDate).format("MM/DD/YYYY"),
      sortable: true,
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = searchText
    ? announcements.filter((item) => {
      return item?.auctionName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.managerName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        item?.sportName?.toLowerCase().includes(searchText?.toLowerCase())
    })
    : announcements;

  useEffect(() => {
    const AnnouncementMsgFunc = async () => {
      await GetAnnouncementGetAllAuction().then((response) => {

        const sortedData = response?.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        sortedData.reverse();
        setAnnouncements([...sortedData]);
      });
    };
    AnnouncementMsgFunc();
  }, []);



  useEffect(() => {
    const AnnouncementDataFunc = async () => {
      await AnnouncementData().then((response) => {


      });
    };
    AnnouncementDataFunc();
  }, []);
  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group">
        <Sidebar data={data} />
        <div
          className={
            data ? "dashboard-content full-contnet" : "dashboard-content"
          }
        >
          <Row>
            <Col md={6}>
              <h1 className="heading-dash">Auction Result</h1>
            </Col>
          </Row>

          <div className="group_data_table announcement">
            <div className="search-input d-flex align-items-center">
              <label className="me-3">Search</label>
              <input
                type="text"
                className="form-control mb-3 mt-3"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
              />
            </div>
            <DataTable
              title="Announcement"
              columns={columns}
              data={filteredData}
              pagination
              expandableRows
              expandableRowExpanded={(row) => row.defaultExpanded}
              expandableRowsComponent={({ data }) => (
                <ExpandedComponent data={data} />
              )}
              expandableRowsComponentProps={{ additionalProp: (row) => row.auctionName }}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 30]}
              paginationTotalRows={filteredData?.length}
            />
          </div>
        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  );
};


